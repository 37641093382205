import ADDRESS from "@/setting";
import axios from "axios"
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";
import { DocumentStatus } from "@/model/dashboard";


export default class DashboardServices {
  public getOrderStatus() {
    return new Promise<Array<DocumentStatus>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/dashboard/order-status`);
      let docs: Array<DocumentStatus> = response.data.results.map((el: any) => new DocumentStatus(el))
      resolve(docs)
    })
  }
  public getReceiveStatus() {
    return new Promise<Array<DocumentStatus>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/dashboard/receive-status`);
      let docs: Array<DocumentStatus> = response.data.results.map((el: any) => new DocumentStatus(el))
      resolve(docs)
    })
  }
  public getDeliveryStatus() {
    return new Promise<Array<DocumentStatus>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/dashboard/delivery-status`);
      let docs: Array<DocumentStatus> = response.data.results.map((el: any) => new DocumentStatus(el))
      resolve(docs)
    })
  }
  public getTransferStatus() {
    return new Promise<Array<DocumentStatus>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/dashboard/transfer-status`);
      let docs: Array<DocumentStatus> = response.data.results.map((el: any) => new DocumentStatus(el))
      resolve(docs)
    })
  }
  public getRefundStatus() {
    return new Promise<Array<DocumentStatus>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/dashboard/refund-status`);
      let docs: Array<DocumentStatus> = response.data.results.map((el: any) => new DocumentStatus(el))
      resolve(docs)
    })
  }
  public getAdjustmentStatus() {
    return new Promise<Array<DocumentStatus>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/dashboard/adjustment-status`);
      let docs: Array<DocumentStatus> = response.data.results.map((el: any) => new DocumentStatus(el))
      resolve(docs)
    })
  }

}
