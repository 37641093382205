import ADDRESS from "@/setting";
import axios from "axios"
import Model from "@/model/product";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";

let singular = 'product'
let plural = 'products'

export default class ProductServices {
  public getProductList(query: any) {
    return new Promise<PaginateResponse<Model>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/${plural}/paginate`, query);
      
      let docs: Array<Model> = response.data.docs.map((el: any) => new Model(el))

      let pages = response.data.totalPages
      let total = response.data.totalDocs
      let paginateResponse = new PaginateResponse<Model>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public getProductSingle(id: string) {
    return new Promise<Model>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/${singular}/get/${id}`);
      let doc: Model = new Model(response.data.doc)
      resolve(doc)
    })
  }

  public deleteProductSingle(id: string) {
    return new Promise<Model>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/${singular}/delete/${id}`);
      let doc: Model = new Model(response.data.doc)
      resolve(doc)
    })
  }

  public updateProduct(id: string, mModel: Model) {
    return new Promise<Model>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/${singular}/update/${id}`, mModel.toJSON());

      let doc: Model = new Model(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public createProduct(mModel: Model) {
    return new Promise<Model>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/${singular}/create/`, mModel.toJSON());
      let doc: Model = new Model(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
}
