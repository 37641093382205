import { PaginateResponse } from "@/common";
import RefundModel from "@/model/refund";
import RefundServices from "@/service/refund";
import { DateTime } from "luxon";
import { Service } from 'typedi';

@Service()
export class ActiveRefundSingleClass {
  data?: RefundModel;
  loading: boolean = true;
  async getRefundSingle(id: string) {
    this.loading = true
    this.data = await new RefundServices().getRefundSingle(id)
    this.loading = false
  }
  async updateRefundSingle() {
    this.loading = true
    this.data = await new RefundServices().updateRefund(this.data!._id!, this.data!)
    this.data = await new RefundServices().getRefundSingle(this.data!._id!)
    this.loading = false
  }
  async createRefundSingle() {
    this.loading = true
    this.data = await new RefundServices().createRefund(this.data!)
    this.loading = false
  }
  async deleteRefundSingle(id: string) {
    this.loading = true
    this.data = await new RefundServices().deleteRefundSingle(id)
    this.loading = false
  }
}

@Service()
export class ActiveRefundListClass {
  data?: PaginateResponse<RefundModel>;
  loading: boolean = true;

  async getRefundList(query: any) {
    this.loading = true
    this.data = await new RefundServices().getRefundList(query)
    this.loading = false
  }

  async deleteRefundFromList(id: string) {
    this.loading = true
    let tmp = await new RefundServices().deleteRefundSingle(id)
    let index = this.data!.docs.findIndex(doc => doc._id === id)
    this.data!.docs.splice(index, 1)
    this.loading = false
  }

}