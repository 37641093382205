import { PaginateResponse } from "@/common";
import ReportModel, {ReportB01Model,ReportA01ItemsModel,ReportB02Model,ReportB03Model} from "@/model/report";
import ReportServices from "@/service/report";
import { DateTime } from "luxon";
import { Service } from 'typedi';



@Service()
export class ActiveReportListClass {
  data?: Array<ReportA01ItemsModel>;
  loading: boolean = true;
  async getReport06(query: any) {
    this.loading = true
    this.data = await new ReportServices().getReportA01(query)
    this.loading = false
  }
}

  // async getReport06(query: any) {
  //   this.loading = true
  //   this.data = await new ReportServices().getReport06(query)
  //   this.loading = false
  // }


@Service()
export class ActiveReportB01Class {
  data?: ReportB01Model
  loading: boolean = true;
  async getReportB01(query: any) {
    this.loading = true
    this.data = await new ReportServices().getReportB01(query)
    this.loading = false
  }
}
@Service()
export class ActiveReportB02Class {
  data?: ReportB02Model;
  loading: boolean = true;
  async getReportB02(query: any) {
    this.loading = true
    this.data = await new ReportServices().getReportB02(query)
    this.loading = false
  }
}
@Service()
export class ActiveReportB03Class {
  data?: ReportB03Model;
  loading: boolean = true;
  async getReportB03(query: any) {
    this.loading = true
    this.data = await new ReportServices().getReportB03(query)
    this.loading = false
  }
}