import CustomerModel from "../customer"

export default class ProjectModel {
  _id?: string
  number: string
  name: string
  code: string
  email: string
  taxId: string
  telephone: string
  contactName: string
  address: string
  customer?: CustomerModel
  customer_id: string | CustomerModel
  contact: string
  sequence: string
  category: string
  groupCode: string
  series: string
  color: string
  remark: string
  length: string
  width: string
  height: string
  customerName: string
  contract: string
  condition: string
  warehouses: Array<ProjectWarehouseModel>
  uploadImages: Array<{ path: string, size: number }>
  unit: string
  createdAt: Date
  createdIP: string
  modifiedAt: Date
  modifiedIP: string
  constructor(jsonData: any) {
    
    this._id = jsonData._id;
    this.number = jsonData.number ?? "";
    this.sequence = jsonData.sequence ?? "";
    this.category = jsonData.category ?? "";
    this.groupCode = jsonData.groupCode ?? "";
    this.series = jsonData.series ?? "";
    this.color = jsonData.color ?? "";
    this.remark = jsonData.remark ?? "";
    this.customer = jsonData.customer==undefined?undefined:new CustomerModel(jsonData.customer)
    this.customer_id = jsonData.customer_id ?? "";
    this.customerName = jsonData.customerName ?? "";
    this.telephone = jsonData.telephone ?? "";
    this.taxId = jsonData.taxId ?? "";
    this.address = jsonData.address ?? "";
    this.contactName = jsonData.contactName ?? "";
    this.contact = jsonData.contact ?? "";
    this.length = jsonData.length ?? "";
    this.width = jsonData.width ?? "";
    this.height = jsonData.height ?? "";
    this.name = jsonData.name ?? "";
    this.code = jsonData.code ?? "";
    this.email = jsonData.email ?? "";
    this.contract = jsonData.contract ?? "";
    this.condition = jsonData.condition ?? "";
    this.uploadImages = (jsonData.uploadImages ?? []).map((m: any) => new (m));
    this.warehouses = (jsonData.warehouses ?? []).map((m: any) => new ProjectWarehouseModel(m));
    this.unit = jsonData.unit ?? "";
    this.createdAt = new Date(jsonData.createdAt)
    this.createdIP = jsonData.createdIP ?? "";
    this.modifiedAt = new Date(jsonData.modifiedAt)
    this.modifiedIP = jsonData.modifiedIP ?? "";
  }
  get customerFullName() {
    try {
      return this.customer!.name
    } catch (error) {
      return "-"
    }
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    obj.uploadImages.forEach((o: any) => {
      try {
        
      } catch (error) {
        
      }
    })
    obj.warehouses.forEach((o: any) => {
      try {
        
      } catch (error) {
        
      }
    })
    return obj
  }
}

class ProjectWarehouseModel {
  _id?: string
  warehouse: string
  warehouseName: string
  qty: number
  lots: Array<{ name: string, size: number }>
  constructor(jsonData: any) {
    this._id = jsonData._id ?? "";
    this.warehouse = jsonData.warehouse ?? "";
    this.warehouseName = jsonData.warehouseName ?? "";
    this.qty = jsonData.qty ?? 0;
    this.lots = jsonData.lots.map((m: any) => new (m));
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    obj.lots.forEach((o: any) => {
      try {
        
      } catch (error) {
        
      }
    })
    return obj
  }
}