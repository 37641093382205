import ProductModel from "../product"
import WarehouseModel from "../warehouse"

export default class ReceiveModel {
  _id: string
  number: string
  note: string
  remark: string
  qty: number
  warehouse: WarehouseModel | string
  arrivalDate: Date
  warehouseName: string
  storeName: string
  handler: string
  inspector: string
  status: string
  sequence: string
  items: Array<ReceiveItemModel>
  address1: string
  address2: string
  createdAt: Date
  createdIP: string

  get qtyFormat() {
    try {
      return this.qty.formatComma()
    } catch (error) {
      return "-"
    }
  }
  constructor(jsonData: any) {
    this._id = jsonData._id
    this.number = jsonData.number ?? ""
    this.note = jsonData.note ?? ""
    this.remark = jsonData.remark ?? ""
    this.qty = jsonData.qty
    this.warehouse = jsonData.warehouse
    this.warehouseName = jsonData.warehouseName
    this.arrivalDate = new Date(jsonData.arrivalDate)
    this.items = (jsonData.items ?? []).map((item: any) => new ReceiveItemModel(item))
    this.storeName = jsonData.storeName
    this.handler = jsonData.handler
    this.inspector = jsonData.inspector
    this.status = jsonData.status ?? "-"
    this.sequence = jsonData.sequence
    this.address1 = jsonData.address1
    this.address2 = jsonData.address2
    this.createdAt = new Date(jsonData.createdAt)
    this.createdIP = jsonData.createdIP
  }
  get totalQty() {
    try {
      let result = this.items.map(item => item.packingList.pkg ?? 0).reduce((a, b) => a + b, 0)
      this.qty = result
      return result
    } catch (error) {
      this.qty = 0
      return 0
    }
  }
  get totalPackingListPkg() {
    try {
      return this.items.map(item => item.packingList.pkg ?? 0).reduce((a, b) => a + b, 0)
    } catch (error) {
      return 0
    }
  }
  get totalCheckListPkg() {
    try {
      return this.items.map(item => item.checkList.pkg ?? 0).reduce((a, b) => a + b, 0)
    } catch (error) {
      return 0
    }
  }
  get totalPackingListPcs() {
    try {
      return this.items.map(item => item.packingList.pcs ?? 0).reduce((a, b) => a + b, 0)
    } catch (error) {
      return 0
    }
  }
  get totalCheckListPcs() {
    try {
      return this.items.map(item => item.checkList.pcs ?? 0).reduce((a, b) => a + b, 0)
    } catch (error) {
      return 0
    }
  }
  get arrivalDateFormat() {
    return this.arrivalDate.toThaiShort()
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return obj
  }
}

export class ReceiveItemModel {
  _id: string
  code: string
  color: string
  length: string
  name: string
  series: string
  package: number
  packingListCode: string
  product: ProductModel | string
  pcs: number
  pkg: number
  pcsPerPkg: number
  packingList: {
    pcsPerPkg: number,
    pkg: number,
    pcs: number,
    unit: string,
    remark: string
  }
  checkList: {
    pcsPerPkg: number,
    pkg: number,
    pcs: number,
    unit: string,
    remark: string
  }
  qty: {
    pkg: number,
    pcs: number,
    remark: string
  }
  remark: string
  constructor(jsonData: any) {
    this._id = jsonData._id
    this.code = jsonData.code
    this.color = jsonData.color
    this.length = jsonData.length
    this.name = jsonData.name
    this.package = jsonData.package
    this.packingList = jsonData.packingList??{}
    this.checkList = jsonData.checkList??{}
    this.qty = jsonData.qty
    this.product = jsonData.product
    this.packingListCode = jsonData.packingListCode
    this.pcs = jsonData.pcs
    this.pkg = jsonData.pkg
    this.pcsPerPkg = jsonData.pcsPerPkg
    this.remark = jsonData.remark
    this.series = jsonData.series
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return obj
  }
}