import CustomerModel from "../customer";
import ProductModel from "../product";
import WarehouseModel from "../warehouse";

export default class OrderModel {
  _id?: string
  quotationNumber: Array<string>;
  method1: Array<string>
  method2: string
  method2Date: Date
  method2Other: String
  method3: string
  method2Percent: string
  number: string;
  revision: number;
  bookingDate: Date;
  dateOfUse: Date;
  salesPerson: Date;
  customer: string;
  project: string;
  status: string;
  notes: Array<OrderNoteModel>
  items: Array<OrderItemModel>
  method4: string
  detail: string
  confirm: string
  step:number
  customer_id: CustomerModel | string
  project_id: ProductModel | string
  address: string
  poNumber: string
  remark: string
  createdAt: Date
  createdIP: string

  constructor(jsonData: any) {
    this._id = jsonData._id
    this.method2 = jsonData.method2
    this.method2Date = jsonData.method2Date
    this.method2Other = jsonData.method2Other
    this.method3 = jsonData.method3
    this.method2Percent = jsonData.method2Percent
    this.poNumber = jsonData.poNumber
    this.remark = jsonData.remark
    this.method2Percent = jsonData.method2Percent
    this.quotationNumber = jsonData.quotationNumber??[]
    this.number = jsonData.number ?? ""
    this.method1 = jsonData.method1??[]
    this.number = jsonData.number
    this.revision = jsonData.revision
    this.bookingDate = new Date(jsonData.bookingDate)
    this.dateOfUse = new Date(jsonData.dateOfUse)
    this.salesPerson = jsonData.salesPerson
    this.customer = jsonData.customer
    this.project = jsonData.project
    this.status = jsonData.status ?? "-"
    this.notes = (jsonData.notes ?? []).map((note: any) => new OrderItemModel(note))
    this.items = (jsonData.items ?? []).map((item: any) => new OrderItemModel(item))
    this.method4 = jsonData.method4
    this.detail = jsonData.detail
    this.confirm = jsonData.confirm
    this.step = jsonData.step
    this.customer_id = jsonData.customer_id
    this.project_id  =jsonData.project_id
    this.address = jsonData.address
    this.createdAt = new Date(jsonData.createdAt)
    this.createdIP = jsonData.createdIP
  }

  get quotations() {
    try {
      return this.quotationNumber.join(", ")
    } catch (error) {
      return "-"
    }
  }

  toJSON() {
    let obj: any = Object.assign({}, this)
    return obj
  }
}
export class OrderNoteModel {
 bindTo:string
 area:string
 note:string
constructor(jsonData:any){

  this.bindTo = jsonData.bindTo
  this.area = jsonData.area
  this.note = jsonData.note
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return obj
  }
}
export class OrderItemModel {
  _id: string
  status: string
  warehouse: string
  product: ProductModel | string
  warehouseName: WarehouseModel | string
  location: string
  series: string
  condition:string
  code: string
  color: string
  size: string
  qty: number
  lot: string
  unit: string
  area: string
  note: string
  length: string
  remark:string

  constructor(jsonData: any) {
    this._id = jsonData._id
    this.status = jsonData.status ?? "รออนุมัติ"
    this.warehouse = jsonData.warehouse
    this.product = jsonData.product
    this.warehouseName = jsonData.warehouseName
    this.location = jsonData.location
    this.series = jsonData.series
    this.code = jsonData.code
    this.color = jsonData.color
    this.size = jsonData.size
    this.qty = jsonData.qty
    this.lot = jsonData.lot
    this.unit = jsonData.unit
    this.area = jsonData.area
    this.note = jsonData.note
    this.length = jsonData.length
    this.remark = jsonData.remark??""
    this.condition = jsonData.condition??""

  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return obj
  }
}