import { PaginateResponse } from "@/common";
import TransferModel from "@/model/transfer";
import TransferServices from "@/service/transfer";
import { DateTime } from "luxon";
import { Service } from 'typedi';

@Service()
export class ActiveTransferSingleClass {
  data?: TransferModel;
  loading: boolean = true;
  async getTransferSingle(id: string) {
    this.loading = true
    this.data = await new TransferServices().getTransferSingle(id)
    this.loading = false
  }
  async updateTransferSingle() {
    this.loading = true
    let response = await new TransferServices().updateTransfer(this.data!._id!, this.data!)
    
    // this.data = await new TransferServices().getTransferSingle(this.data!._id!)
    this.loading = false
  }
  async createTransferSingle() {
    this.loading = true
    this.data = await new TransferServices().createTransfer(this.data!)
    this.loading = false
  }
  async deleteTransferSingle(id: string) {
    this.loading = true
    this.data = await new TransferServices().deleteTransferSingle(id)
    this.loading = false
  }
}

@Service()
export class ActiveTransferListClass {
  data?: PaginateResponse<TransferModel>;
  loading: boolean = true;

  async getTransferList(query: any) {
    this.loading = true
    this.data = await new TransferServices().getTransferList(query)
    this.loading = false
  }

  async deleteTransferFromList(id: string) {
    this.loading = true
    let tmp = await new TransferServices().deleteTransferSingle(id)
    let index = this.data!.docs.findIndex(doc => doc._id === id)
    this.data!.docs.splice(index, 1)
    this.loading = false
  }

}