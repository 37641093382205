import { PaginateResponse } from "@/common";
import CustomerModel from "@/model/customer";
import CustomerServices from "@/service/customer";
import { DateTime } from "luxon";
import { Service } from 'typedi';

@Service()
export class ActiveCustomerSingleClass {
  data?: CustomerModel;
  loading: boolean = true;
  async getCustomerSingle(id: string) {
    this.loading = true
    this.data = await new CustomerServices().getCustomerSingle(id)
    this.loading = false
  }
  async updateCustomerSingle() {
    this.loading = true
    let response = await new CustomerServices().updateCustomer(this.data!._id!, this.data!)
    this.data = await new CustomerServices().getCustomerSingle(this.data!._id!)
    this.loading = false
  }
  async createCustomerSingle() {
    this.loading = true
    this.data = await new CustomerServices().createCustomer(this.data!)
    this.loading = false
  }
  async deleteCustomerSingle(id: string) {
    this.loading = true
    this.data = await new CustomerServices().deleteCustomerSingle(id)
    this.loading = false
  }
}

@Service()
export class ActiveCustomerListClass {
  data?: PaginateResponse<CustomerModel>;
  loading: boolean = true;

  async getCustomerList(query: any) {
    if(query.limit===undefined) query.limit = 99999
    this.loading = true
    this.data = await new CustomerServices().getCustomerList(query)
    this.loading = false
  }

  async deleteCustomerFromList(id: string) {
    this.loading = true
    let tmp = await new CustomerServices().deleteCustomerSingle(id)
    let index = this.data!.docs.findIndex(doc => doc._id === id)
    this.data!.docs.splice(index, 1)
    this.loading = false
  }

}