let ADDRESS = "https://polymer-master.net/api/v1"
//  let ADDRESS = "http://plm221.primrose-tech.com/api/v1"
// let ADDRESS = "http://192.168.1.7:22120/api/v1"
// let ADDRESS = "http://192.168.1.3:22120/api/v1"
 //let ADDRESS = "http://192.168.68.105:22120/api/v1"
//let ADDRESS = "http://192.168.68.116:22120/api/v1"
// let ADDRESS = "http://localhost:22120/api/v1"

export const APIKEY = "2f66df29a833f9c87023ee0bb728531d"
export let filePath = ADDRESS.replace("api/v1", "api/v1");
export default ADDRESS
