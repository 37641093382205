export default class WarehouseModel {
  _id: string
  name: string
  number: string
  sequence: string
  status: string
  contact: string
  address: string
  isActive: boolean
  size: number 
  capacity: number 
  location:Array<number>
  createdAt: Date
  createdIP: string
  modifiedAt: Date
  modifiedIP: string
  constructor(jsonData: any) {
    this._id = jsonData._id
    this.name = jsonData.name
    this.number = jsonData.number
    this.sequence = jsonData.sequence
    this.status = jsonData.status
    this.contact = jsonData.contact
    this.address = jsonData.address
    this.isActive = jsonData.isActive
    this.size = jsonData.size
    this.capacity = jsonData.capacity
    this.location = jsonData.location
    this.createdAt = new Date(jsonData.createdAt)
    this.createdIP = jsonData.createdIP
    this.modifiedAt = new Date(jsonData.modifiedAt)
    this.modifiedIP = jsonData.modifiedIP
  }
  toJSON() {
    let obj: any = Object.assign({},this)
    return obj
  }
}