import { PaginateResponse } from "@/common";
import DeliveryModel from "@/model/delivery";
import DeliveryServices from "@/service/delivery";
import { DateTime } from "luxon";
import { Service } from 'typedi';

@Service()
export class ActiveDeliverySingleClass {
  data?: DeliveryModel;
  loading: boolean = true;
  async getDeliverySingle(id: string) {
    this.loading = true
    this.data = await new DeliveryServices().getDeliverySingle(id)
    this.loading = false
  }
  async updateDeliverySingle() {
    this.loading = true
    let response = await new DeliveryServices().updateDelivery(this.data!._id!, this.data!)
    this.data = await new DeliveryServices().getDeliverySingle(this.data!._id!)
    this.loading = false
  }
  async createDeliverySingle() {
    this.loading = true
    this.data = await new DeliveryServices().createDelivery(this.data!)
    this.loading = false
  }
  async deleteDeliverySingle(id: string) {
    this.loading = true
    this.data = await new DeliveryServices().deleteDeliverySingle(id)
    this.loading = false
  }
}

@Service()
export class ActiveDeliveryListClass {
  data?: PaginateResponse<DeliveryModel>;
  loading: boolean = true;

  async getDeliveryList(query: any) {
    this.loading = true
    this.data = await new DeliveryServices().getDeliveryList(query)
    this.loading = false
  }

  async deleteDeliveryFromList(id: string) {
    this.loading = true
    let tmp = await new DeliveryServices().deleteDeliverySingle(id)
    let index = this.data!.docs.findIndex(doc => doc._id === id)
    this.data!.docs.splice(index, 1)
    this.loading = false
  }

}