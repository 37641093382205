import { PaginateResponse } from "@/common";
import TransactionModel from "@/model/transaction";
import TransactionServices from "@/service/transaction";
import { DateTime } from "luxon";
import { Service } from 'typedi';

@Service()
export class ActiveTransactionSingleClass {
  data?: TransactionModel;
  loading: boolean = true;
  async getTransactionSingle(id: string) {
    this.loading = true
    this.data = await new TransactionServices().getTransactionSingle(id)
    this.loading = false
  }
  async updateTransactionSingle() {
    this.loading = true
    this.data = await new TransactionServices().updateTransaction(this.data!._id!, this.data!)
    this.data = await new TransactionServices().getTransactionSingle(this.data!._id!)
    this.loading = false
  }
  async createTransactionSingle() {
    this.loading = true
    this.data = await new TransactionServices().createTransaction(this.data!)
    this.loading = false
  }
  async deleteTransactionSingle(id: string) {
    this.loading = true
    this.data = await new TransactionServices().deleteTransactionSingle(id)
    this.loading = false
  }
}

@Service()
export class ActiveTransactionListClass {
  data?: PaginateResponse<TransactionModel>;
  loading: boolean = true;

  async getTransactionList(query: any) {
    this.loading = true
    this.data = await new TransactionServices().getTransactionList(query)
    this.loading = false
  }

  async deleteTransactionFromList(id: string) {
    this.loading = true
    let tmp = await new TransactionServices().deleteTransactionSingle(id)
    let index = this.data!.docs.findIndex(doc => doc._id === id)
    this.data!.docs.splice(index, 1)
    this.loading = false
  }

}