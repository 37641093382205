import CustomerModel from "../customer"
import ProductModel from "../product"
import ProjectModel from "../project"
import WarehouseModel from "../warehouse"

export default class DeliveryModel {
  _id?: string
  method2: string
  method2Percent: string
  method2Date: Date
  method2other: string
  method3: boolean
  contact: string
  contactName: string
  telephone: string
  taxId: string
  email: string
  series: string
  items:Array<DeliveryItemModel>
  number: string
  sequence: string
  order: string
  quotationNumber: Array<string>
  project: string | ProjectModel
  customer_id?: string| CustomerModel
  customer: string
  salesPerson: string
  address1: string
  note: string
  address2:string
  deliveryDate: Date
  poNumber: string
  paymentTerms:string
  status: string
  requisitionType: string
  createdAt: Date
  modifiedAt: Date
  createdIP: string
  modifiedIP: string
  

  constructor(jsonData: any) {
    this._id = jsonData._id;
    this.method3 = jsonData.method3??"";
    this.method2 = jsonData.method2??"";
    this.method2Percent = jsonData.method2Percent??"";
    this.method2Date = new Date(jsonData.method2Date);
    this.method2other = jsonData.method2other??"";
    this.contact = jsonData.contact??"";
    this.contactName = jsonData.contactName??"";
    this.telephone = jsonData.telephone??"";
    this.taxId = jsonData.taxId??"";
    this.series = jsonData.series??"";
    this.email = jsonData.email??"";
    this.items = (jsonData.items??[]).map((m:any)=>new DeliveryItemModel(m));
    this.sequence = jsonData.sequence??"";
    this.order = jsonData.order??"";
    this.quotationNumber = jsonData.quotationNumber;
    this.project = jsonData.project??"";
    this.customer = jsonData.customer??"";
    this.salesPerson = jsonData.salesPerson??"";
    this.address1 = jsonData.address1??"";
    this.address2 = jsonData.address2??"";
    this.note = jsonData.note??"";
    this.poNumber = jsonData.poNumber??"";
    this.paymentTerms = jsonData.paymentTerms??"";
    this.customer_id = jsonData.customer_id
    this.number = jsonData.number??"";
    this.status = jsonData.status??"-";
    this.requisitionType = jsonData.requisitionType??"";
    this.deliveryDate = new Date(jsonData.deliveryDate);
    this.createdAt = new Date(jsonData.createdAt);
    this.modifiedAt = new Date(jsonData.modifiedAt);
    this.createdIP = jsonData.createdIP??"";
    this.modifiedIP = jsonData.modifiedIP??"";
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    try {
      
    obj.items.forEach((o: any) => {
      try {
        
      } catch (error) {
        
      }
    })
    } catch (error) {
      
    }
    try {
      
    obj.quotationNumber.forEach((o:any)=>{
      try {
        
      } catch (error) {
        
      }
    })
    } catch (error) {
      
    }
    return obj
  }
}

export class DeliveryItemModel {
  _id?: string;
  color: string
  lot: string
  note: string
  code: string
  qty: number
  warehouse: string|WarehouseModel
  warehouseName: string
  product: string|ProductModel
  groupCode: string
  condition:string
  size: string
  area: string
  unit: string
  remark:string


  constructor(jsonData: any) {
    this._id = jsonData._id;
    this.color = jsonData.color??"";
    this.lot = jsonData.lot??"";
    this.note = jsonData.note??""
    this.code = jsonData.code??"";
    this.qty = jsonData.qty??0;
    this.warehouse = jsonData.warehouse??"";
    this.warehouseName = jsonData.warehouseName??"";
    this.product = jsonData.product??"";
    this.groupCode = jsonData.groupCode??"";
    this.size = jsonData.size??"";
    this.area = jsonData.area??"";
    this.unit = jsonData.unit??"";
    this.remark = jsonData.remark??""
    this.condition = jsonData.condition??""
  }
  toJSON() {
    let obj = Object.assign({}, this) as any
    return obj
  }
}