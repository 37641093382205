
export default  class ReportModel {
  _id?: string
  warehouses: Array<string>
  items:Array<ReportB01ItemsModel>
  constructor(jsonData:any) {
    this._id = jsonData._id
    this.warehouses = jsonData.warehouses
    this.items = (jsonData.items ?? []).map((item: any) => new ReportB01ItemsModel(item))
  }
  get warehouseLength(){
    try {
      return this.warehouses.length
    } catch (error) {
      return 0
    }
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return obj
  }
}
//B01
export  class ReportB01Model {
  _id?: string
  warehouses: Array<string>
  items:Array<ReportB01ItemsModel>
  constructor(jsonData:any) {
    this._id = jsonData._id
    this.warehouses = jsonData.warehouses?? []
    this.items = (jsonData.items ?? []).map((item: any) => new ReportB01ItemsModel(item))
  }
  get warehouseLength(){
    try {
      return (this.warehouses??[]).length
    } catch (error) {
      return 0
    }
  }
  getWarehouseLength(){
    try {
      return (this.warehouses??[]).length
    } catch (error) {
      return 0
    }
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return obj
  }
}
export class ReportB01ItemsModel {
  _id?: string
  series: string
  code: string
  color: string
  length: string
  series_index: number
  code_index: number
  color_index: number
  length_index: number
  total:number
  value: Array<number>
  

  constructor(jsonData:any){
    this._id = jsonData._id
    this.series = jsonData.series
    this.code = jsonData.code
    this.color = jsonData.color
    this.length = jsonData.length
    this.series_index =  jsonData.series_index
    this.code_index =  jsonData.code_index
    this.color_index =  jsonData.color_index
    this.length_index =  jsonData.length_index
    this.value = jsonData.values
    this.total = jsonData.total
    
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return obj
  }
}
//B02
export class ReportB02Model {
  _id?: string
  warehouses: Array<string>
  items:Array<ReportB02ItemsModel>

  constructor(jsonData:any){
    this._id = jsonData._id
    this.warehouses = jsonData.warehouses?? []
    this.items = (jsonData.items ?? []).map((item: any) => new ReportB01ItemsModel(item))
  }
  get warehouseLength(){
    try {
      return (this.warehouses??[]).length
    } catch (error) {
      return 0
    }
  }
  getWarehouseLength(){
    try {
      return (this.warehouses??[]).length
    } catch (error) {
      return 0
    }
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return obj
  }
}
export class ReportB02ItemsModel {
  _id?: string
  series: string
  code: string
  color: string
  length: string
  remark: string
  series_index: number
  code_index: number
  color_index: number
  length_index: number
  total:number
  value: Array<number>;



  constructor(jsonData:any){
    this._id = jsonData._id
    this.series = jsonData.series
    this.code = jsonData.code
    this.color = jsonData.color
    this.length = jsonData.length
    this.remark = jsonData.remark
    this.value = jsonData.value
    this.series_index =  jsonData.series_index
    this.code_index =  jsonData.code_index
    this.color_index =  jsonData.color_index
    this.length_index =  jsonData.length_index
    this.total = jsonData.total

  }
}
//B03
export class ReportB03Model {
  _id?: string
  warehouses: Array<string>
  items:Array<ReportB03ItemsModel>

  constructor(jsonData:any){
    this._id = jsonData._id
    this.warehouses = jsonData.warehouses?? []
    this.items = (jsonData.items ?? []).map((item: any) => new ReportB03ItemsModel(item))
  }
  get warehouseLength(){
    try {
      return (this.warehouses??[]).length
    } catch (error) {
      return 0
    }
  }
  getWarehouseLength(){
    try {
      return (this.warehouses??[]).length
    } catch (error) {
      return 0
    }
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return obj
  }
}
export class ReportB03ItemsModel {
  _id?: string
  series: string
  code: string
  color: string
  length: string
  series_index: number
  code_index: number
  color_index: number
  length_index: number
  total:number
  value: Array<number>

  constructor(jsonData:any) {
    this._id = jsonData._id
    this.series = jsonData.series
    this.code = jsonData.code
    this.color = jsonData.color
    this.length = jsonData.length
    this.value = jsonData.value
    this.series_index =  jsonData.series_index
    this.code_index =  jsonData.code_index
    this.color_index =  jsonData.color_index
    this.length_index =  jsonData.length_index
    this.total = jsonData.total

  }
}
//B04
export class ReportB04Model {
  _id?: string
  finalResults:Array<ReportB04ItemsModel>

  constructor(jsonData:any){
    this._id = jsonData._id
    this.finalResults = (jsonData.finalResults ?? []).map((item: any) => new ReportB04ItemsModel(item))
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return obj
  }
}
export class ReportB04ItemsModel {
  _id?: string
  sales: string
  bookingDate: Date
  number: string
  quotationNumber: string
  customer: string
  paymentDate: Date
  method2Percent:number
  method2: string
  method1: string
  code: string
  color: string
  length: string
  qty: string
  i: number
  j: number
  k: number

  constructor(jsonData:any) {
    this._id = jsonData._id
    this.sales = jsonData.sales
    this.bookingDate = new Date(jsonData.bookingDate)
    this.number = jsonData.number
    this.quotationNumber = jsonData.quotationNumber
    this.customer = jsonData.customer
    this.paymentDate = new Date(jsonData.paymentDate)
    this.method2Percent = jsonData.method2Percent
    this.method2 = jsonData.method2
    this.method1 = jsonData.method1
    this.code = jsonData.code
    this.color = jsonData.color
    this.length = jsonData.length
    this.qty = jsonData.qty
    this.i = jsonData.i
    this.j = jsonData.j
    this.k = jsonData.k
  }
}
//B05
export class ReportB05Model {
  _id?: string
  items:Array<ReportB05ItemsModel>

  constructor(jsonData:any) {
    this._id = jsonData._id
    this.items = (jsonData.items ?? []).map((item: any) => new ReportB05ItemsModel(item))
  }
}
export class ReportB05ItemsModel {
  _id?: string
  code: string
  length: string
  color: string
  quotationNumber: Array<string>
  remark: string
  customer: string
  sales: string
  createdAt: Date
  payment: string
  value: number
  series_index: number
  groupCode_index: number
  code_index: number
  color_index: number
  length_index: number
  item_index: number

  constructor(jsonData:any){
    this._id = jsonData._id
    this.code = jsonData.code
    this.length = jsonData.length
    this.color = jsonData.color
    this.quotationNumber = jsonData.quotationNumber??[]
    this.createdAt = new Date(jsonData.createdAt)
    this.payment = jsonData.payment
    this.value = jsonData.value
    this.customer = jsonData.customer
    this.remark = jsonData.remark
    this.sales = jsonData.sales
    this.series_index = jsonData.series_index
    this.groupCode_index = jsonData.groupCode_index
    this.code_index = jsonData.code_index
    this.color_index = jsonData.color_index
    this.length_index = jsonData.length_index
    this.item_index = jsonData.item_index
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return obj
  }
}
//B06
export class ReportB06Model {
  _id?: string
  finalResults:Array<ReportB06ItemsModel>

  constructor(jsonData:any){
    this._id = jsonData._id
    this.finalResults = (jsonData.finalResults ?? []).map((item: any) => new ReportB06ItemsModel(item))
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return obj
  }
}
export class ReportB06ItemsModel {
  _id?: string
  sales: string
  bookingDate: Date
  number: string
  quotationNumber: string
  customer: string
  paymentDate: Date
  method2Percent:number
  method2: string
  method1: string
  code: string
  color: string
  warehouse: string
  length: string
  qty: string
  i: number
  j: number
  k: number

  constructor(jsonData:any) {
    this._id = jsonData._id
    this.sales = jsonData.sales
    this.bookingDate = new Date(jsonData.bookingDate)
    this.number = jsonData.number
    this.quotationNumber = jsonData.quotationNumber
    this.customer = jsonData.customer
    this.paymentDate = new Date(jsonData.paymentDate)
    this.method2Percent = jsonData.method2Percent
    this.method2 = jsonData.method2
    this.method1 = jsonData.method1
    this.code = jsonData.code
    this.color = jsonData.color
    this.length = jsonData.length
    this.warehouse = jsonData.warehouse
    this.qty = jsonData.qty
    this.i = jsonData.i
    this.j = jsonData.j
    this.k = jsonData.k
  }
}
//A01
export class ReportA01Model {
  _id?: string
  items:Array<ReportA01ItemsModel>

  constructor(jsonData:any){
    this._id = jsonData._id
    this.items = (jsonData.items ?? []).map((item: any) => new ReportA01ItemsModel(item))
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return obj
  }
}
export class ReportA01ItemsModel {
  _id?: string
  series: string
  groupCode: string
  code: string
  color: string
  length: string
  series_index: number
  groupCode_index: number
  code_index: number
  color_index: number
  length_index: number
  value: number

  constructor(jsonData:any) {
    this._id = jsonData._id
    this.series = jsonData.series
    this.groupCode = jsonData.groupCode
    this.code = jsonData.code
    this.color = jsonData.color
    this.length = jsonData.length
    this.series_index = jsonData.series_index
    this.groupCode_index = jsonData.groupCode_index
    this.code_index = jsonData.code_index
    this.color_index = jsonData.color_index
    this.length_index = jsonData.length_index
    this.value = jsonData.value
  }
}
//A02
export class ReportA02Model {
  _id?: string
  warehouses:Array<string>
  items:Array<ReportA02ItemsModel>

  constructor(jsonData:any){
    this._id = jsonData._id
    this.warehouses = jsonData.warehouses??[]
    this.items = (jsonData.items ?? []).map((item: any) => new ReportA02ItemsModel(item))
  }
  get warehousesLength() {
    try {
      
      return this.warehouses.length
      
    } catch (error) {
      return 0
    }
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return obj
  }
}
export class ReportA02ItemsModel {
  _id?: string
  series: string
  groupCode: string
  color: string
  code: string
  length: string
  series_index: number
  groupCode_index: number
  code_index: number
  color_index: number
  length_index: number
  total:number
  value: Array<number>

  constructor(jsonData:any) {
    this._id = jsonData._id
    this.series = jsonData.series
    this.groupCode = jsonData.groupCode
    this.color = jsonData.color
    this.code = jsonData.code
    this.length = jsonData.length
    this.series_index = jsonData.series_index
    this.groupCode_index = jsonData.groupCode_index
    this.code_index = jsonData.code_index
    this.color_index = jsonData.color_index
    this.length_index = jsonData.length_index
    this.total = jsonData.total
    this.value = jsonData.value

  }
}
//A03
export class ReportA03Model {
  _id?: string
  warehouses:Array<string>
  items:Array<ReportA03ItemsModel>

  constructor(jsonData:any){
    this._id = jsonData._id
    this.warehouses = jsonData.warehouses??[]
    this.items = (jsonData.items ?? []).map((item: any) => new ReportA03ItemsModel(item))
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return obj
  }
}
export class ReportA03ItemsModel {
  _id?: string
  series: string
  groupCode: string
  code: string
  color: string
  length: string
  series_index: number
  groupCode_index: number
  code_index: number
  color_index: number
  length_index: number
  total: number
  value: Array<number>

  constructor(jsonData:any){
    this._id = jsonData._id
    this.series = jsonData.series
    this.groupCode = jsonData.groupCode
    this.code = jsonData.code
    this.color = jsonData.color
    this.length = jsonData.length
    this.series_index = jsonData.series_index
    this.groupCode_index = jsonData.groupCode_index
    this.code_index = jsonData.code_index
    this.color_index = jsonData.color_index
    this.length_index = jsonData.length_index
    this.total = jsonData.total
    this.value = jsonData.value
  }
}
