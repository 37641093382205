import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import Portal from '../views/Portal.vue'
import PortalRoutes from "./portal"
import Login from '../views/Login.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Portal
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/portal',
    name: 'Portal',
    component: Portal,
    children: PortalRoutes
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

import store from "@/store/index"

router.beforeEach((to, from, next) => {

  if (to.path === '/login') next()
  if (store.state.userIsLogin) next()
  else next('/login')
})

export default router
