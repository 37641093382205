export default class ProductModel {
  _id: string
  code: string
  uploadImages: Array<{ path: string }>
  series: string
  color: string
  length: string
  groupCode: string
  unit:string
  warehouses: Array<any>
  condition: string
  createdAt: Date
  createdIP: string
  remark:string
  constructor(jsonData: any) {
    this._id = jsonData._id
    this.code = jsonData.code
    this.uploadImages = jsonData.uploadImages
    this.series = jsonData.series
    this.color = jsonData.color
    this.length = jsonData.length
    this.groupCode = jsonData.groupCode
    this.warehouses = jsonData.warehouses
    this.condition = jsonData.condition
    this.unit = jsonData.unit
    this.createdAt = new Date(jsonData.createdAt)
    this.createdIP = jsonData.createdIP
    this.remark = jsonData.remark??""
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return obj
  }
}