import ProductModel from "../product"
import WarehouseModel from "../warehouse"

export default class AdjustmentModel {
  _id: string
  number: string
  sequence:string
  status: string
  items: Array<AdjustmentItemModel>
  remark:string
  createdAt: Date
  createdIP: string
  constructor(jsonData: any) {
    this._id = jsonData._id
    this.number = jsonData.number ?? ""
    this.remark = jsonData.remark ?? ""
    this.status = jsonData.status ?? "-"
    this.sequence = jsonData.sequence
    this.items = jsonData.items
    this.createdAt = new Date(jsonData.createdAt)
    this.createdIP = jsonData.createdIP
  }
  get length() {
    try {
      return this.items.length
    } catch (error) {
      return 0
    }
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return obj
  }
}

export class AdjustmentItemModel {
  _id?: string
  product: string | ProductModel
  code:string
  color:string
  condition:string
  lot:string
  length:string
  qty: Number
  remark:string
  children?:Array<AdjustmentItemChildrenModel>
  constructor(jsonData: any) {
    this._id = jsonData._id
    this.product = jsonData.product
    this.code = jsonData.code
    this.color = jsonData.color
    this.condition = jsonData.condition
    this.lot = jsonData.lot
    this.length = jsonData.length
    this.children = (jsonData.children??[]).map((item:any)=>new AdjustmentItemChildrenModel(item))
    this.qty = jsonData.qty
    this.remark = jsonData.remark
  }
}

class AdjustmentItemChildrenModel {
  _id?: string
   warehouse: string| WarehouseModel
   warehouseName:string
   condition:string
   actual:number 
   qty:number
   lot:string
   remark:string

  constructor(jsonData:any){
     this._id = jsonData._id
     this.warehouse = jsonData.warehouse
     this. warehouseName = jsonData.warehouseName
     this.condition = jsonData.condition
     this.actual = jsonData.actual
     this.qty = jsonData.qty
     this.lot = jsonData.lot
     this.remark = jsonData.remark

  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return obj
  }
}