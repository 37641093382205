import { PaginateResponse } from "@/common";
import ReceiveModel from "@/model/receive";
import ReceiveServices from "@/service/receive";
import { DateTime } from "luxon";
import { Service } from 'typedi';

@Service()
export class ActiveReceiveSingleClass {
  data?: ReceiveModel;
  loading: boolean = true;

  get totalPackingListPcs() {
    return this.data?.items.map((el:any)=>el.pscPerPkg??[]).reduce((a:any,b:any)=>a+b,0).formatComma()
  }
  get totalCheckListPcs() {
    return this.data?.items.map((el:any)=>el.pcs??[]).reduce((a:any,b:any)=>a+b,0).formatComma()
  }
  async getReceiveSingle(id: string) {
    this.loading = true
    this.data = await new ReceiveServices().getReceiveSingle(id)
    this.loading = false
  }
  async updateReceiveSingle() {
    this.loading = true
    this.data = await new ReceiveServices().updateReceive(this.data!._id!, this.data!)
    this.data = await new ReceiveServices().getReceiveSingle(this.data!._id!)
    this.loading = false
  }
  async createReceiveSingle() {
    this.loading = true
    this.data = await new ReceiveServices().createReceive(this.data!)
    this.loading = false
  }
  async deleteReceiveSingle(id: string) {
    this.loading = true
    this.data = await new ReceiveServices().deleteReceiveSingle(id)
    this.loading = false
  }
}

@Service()
export class ActiveReceiveListClass {
  data?: PaginateResponse<ReceiveModel>;
  loading: boolean = true;

  async getReceiveList(query: any) {
    this.loading = true
    this.data = await new ReceiveServices().getReceiveList(query)
    this.loading = false
  }

  async deleteReceiveFromList(id: string) {
    this.loading = true
    let tmp = await new ReceiveServices().deleteReceiveSingle(id)
    let index = this.data!.docs.findIndex(doc => doc._id === id)
    this.data!.docs.splice(index, 1)
    this.loading = false
  }

}