export class DocumentStatus {
  _id: string
  count: number
  constructor(jsonData: any) {
    this._id = jsonData._id
    this.count = jsonData.count
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return obj
  }
}