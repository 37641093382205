import { PaginateResponse } from "@/common";
import SettingModel from "@/model/setting";
import SettingServices from "@/service/setting";
import { DateTime } from "luxon";
import { Service } from 'typedi';

@Service()
export class ActiveSettingSingleClass {
  data?: SettingModel;
  loading: boolean = true;
  async getSettingSingle(id: string) {
    this.loading = true
    this.data = await new SettingServices().getSettingSingle(id)
    this.loading = false
  }
  async updateSettingSingle() {
    this.loading = true
    this.data = await new SettingServices().updateSetting(this.data!._id!, this.data!)
    this.data = await new SettingServices().getSettingSingle(this.data!._id!)
    this.loading = false
  }
  async createSettingSingle() {
    this.loading = true
    this.data = await new SettingServices().createSetting(this.data!)
    this.loading = false
  }
  async deleteSettingSingle(id: string) {
    this.loading = true
    this.data = await new SettingServices().deleteSettingSingle(id)
    this.loading = false
  }
}

@Service()
export class ActiveSettingListClass {
  data?: PaginateResponse<SettingModel>;
  loading: boolean = true;

  async getSettingList(query: any) {
    this.loading = true
    this.data = await new SettingServices().getSettingList(query)
    this.loading = false
  }

  async deleteSettingFromList(id: string) {
    this.loading = true
    let tmp = await new SettingServices().deleteSettingSingle(id)
    let index = this.data!.docs.findIndex(doc => doc._id === id)
    this.data!.docs.splice(index, 1)
    this.loading = false
  }

}