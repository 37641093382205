let mm = (u: number) => {
  return u * 0.352777778;
};
let getHeader = (
  name: string,
  store: any,
  doc: any,
  path?: string,
  date?: any
) => {
  return {
    fillColor: "#F3F3F3",
    margin: [0, 0, 0, 0],
    stack: [
      {
        layout: "noBorders",
        table: {
          widths: ["*", 150, 80],
          body: [
            [
              { qr: path + doc._id, fit: '40', marginTop: 10, marginLeft: 20 },
              {
                text: "บริษัท โพลีเมอร์ มาสเตอร์ จำกัด",
                bold: true,
                margin: [0, 20, 0, 0]
              },
              {
                image: store.base64Logo,
                height: 30,
                width: 70,
                margin: [0, 15, 20, 0]
              },
            ],
          ]
        }
      },
      {
        layout: "noBorders",
        table: {
          widths: ["*"],
          body: [
            [
              {
                text: name,
                bold: true,
                fontSize: 25,
                alignment: 'left',
                margin: [20, 0, 0, 0],
              }
            ],
            [
              {
                text: [
                  { text: 'NO : ', bold: true },
                  { text: doc.number, }
                ],
                bold: true,
                margin: [20, -10, 0, 0],
              }
            ],
          ]
        }
      },
      {
        layout: "noBorders",
        table: {
          widths: [80, 100, "*", 170],
          body: [
            [
              {
                text: 'ติดต่อบริษัท',
                bold: true,
                margin: [20, 0, 0, 0],
              }, "", "",
              {
                text: "วันที่สร้างเอกสารบนระบบ",
                alignment: "right",
                bold: true,
                margin: [0, 0, 20, 0],
              }
            ],
            [
              { text: "เบอร์โทรศัพท์", bold: true, margin: [20, 0, 0, 0] },
              { text: "อีเมล", bold: true, },
              { text: "ที่อยู่", bold: true, },
              {
                text: `${new Date(date).toThaiShort()}`,
                alignment: "right", margin: [0, 0, 20, 0]
              }
            ],
            [
              { text: "02 956 1048-9", fontSize: 12, margin: [20, 0, 0, 0] },
              { text: "pm@polymer-master.com", fontSize: 12 },
              { text: "136 ซอยสังคมสงเคราะห์24 แขวงลาดพร้าว", fontSize: 12 },
              "",
            ],
            [
              "", "",
              { text: "เขตลาดพร้าว กรุงเทพมหานคร 10230", fontSize: 12 },
              "",
            ],
          ]
        }
      },
      {
        canvas: [
          {
            type: "line",
            x1: mm(60),
            y1: 5,
            x2: mm(1630),
            y2: 5,
            lineWidth: 0.2,
          },
        ],
      },
    ],
  };
};
let getHeaderNoAddress = (
  name: string,
  store: any,
  doc: any,
  path?: string,
  date?: any
) => {
  return {
    fillColor: "#F3F3F3",
    margin: [0, 0, 0, 0],
    stack: [
      {
        layout: "noBorders",
        table: {
          widths: ["*", 150, 80],
          body: [
            [
              { qr: path + doc._id, fit: '40', marginTop: 10, marginLeft: 20 },
              {
                text: "บริษัท โพลีเมอร์ มาสเตอร์ จำกัด",
                bold: true,
                margin: [0, 20, 0, 0]
              },
              {
                image: store.base64Logo,
                height: 30,
                width: 70,
                margin: [0, 15, 20, 0]
              },
            ],
          ]
        }
      },
      {
        layout: "noBorders",
        table: {
          widths: ["*"],
          body: [
            [
              {
                text: name,
                bold: true,
                fontSize: 25,
                alignment: 'left',
                margin: [20, 0, 0, 0],
              }
            ],
            [
              {
                text: [
                  {
                    text: 'NO : ', bold: true,
                    fontSize: 20,
                  },
                  {
                    text: doc.number,
                    fontSize: 20,
                  }
                ],
                bold: true,
                margin: [20, -10, 0, 0],
              }
            ],
          ]
        }
      },
      {
        layout: "noBorders",
        table: {
          widths: ["*"],
          body: [
            [
              {
                text: "วันที่สร้างเอกสารบนระบบ",
                alignment: "right",
                bold: true,
                margin: [0, 0, 20, 0],
              }
            ],
            [
              {
                text: `${new Date(date).toThaiShort()}`,
                alignment: "right", margin: [0, 0, 20, 0]
              }
            ],
          ]
        }
      },
      {
        canvas: [
          {
            type: "line",
            x1: mm(60),
            y1: 5,
            x2: mm(1630),
            y2: 5,
            lineWidth: 0.2,
          },
        ],
      },
    ],
  };
};

let getHeaderNoAddressLandscape = (
  name: string,
  store: any,
  doc: any,
  path?: string,
  date?: any
) => {
  return {
    fillColor: "#F3F3F3",
    margin: [0, 0, 0, 0],
    stack: [
      {
        layout: "noBorders",
        table: {
          widths: ["*", 150, 80],
          body: [
            [
              { qr: path + doc._id, fit: '40', marginTop: 10, marginLeft: 20 },
              {
                text: "บริษัท โพลีเมอร์ มาสเตอร์ จำกัด",
                bold: true,
                margin: [0, 20, 0, 0]
              },
              {
                image: store.base64Logo,
                height: 30,
                width: 70,
                margin: [0, 15, 20, 0]
              },
            ],
          ]
        }
      },
      {
        layout: "noBorders",
        table: {
          widths: ["*"],
          body: [
            [
              {
                text: name,
                bold: true,
                fontSize: 25,
                alignment: 'left',
                margin: [20, 0, 0, 0],
              }
            ],
            [
              {
                text: [
                  {
                    text: 'NO : ', bold: true,
                    fontSize: 20,
                  },
                  {
                    text: doc.number,
                    fontSize: 20,
                  }
                ],
                bold: true,
                margin: [20, -10, 0, 0],
              }
            ],
          ]
        }
      },
      {
        layout: "noBorders",
        table: {
          widths: ["*"],
          body: [
            [
              {
                text: "วันที่สร้างเอกสารบนระบบ",
                alignment: "right",
                bold: true,
                margin: [0, 0, 20, 0],
              }
            ],
            [
              {
                text: `${new Date(date).toThaiShort()}`,
                alignment: "right", margin: [0, 0, 20, 0]
              }
            ],
          ]
        }
      },
      // {
      //   canvas: [
      //     {
      //       type: "line",
      //       x1: mm(60),
      //       y1: 5,
      //       x2: mm(1630),
      //       y2: 5,
      //       lineWidth: 0.2,
      //     },
      //   ],
      // },
    ],
  };
};
let getHeaderUpper = (
  name: string,
  store: any,
  doc: any,
  path?: string,
  date?: any,
) => {
  return {
    fillColor: "#F3F3F3",
    margin: [0, 0, 0, 0],
    stack: [
      {
          //
              // { qr: path + doc._id, fit: '40', marginTop:10, marginLeft:20 },
        layout: "noBorders",
        table: {
          widths: ["*", "*"],
          body: [
            [
              {
                text: "บริษัท โพลีเมอร์ มาสเตอร์ จำกัด",
                bold: true,
                margin: [20, 20, 20, 0],
                alignment: 'left'
              },
              {
                image: store.base64Logo,
                height: 30,
                width: 70,
                margin: [20, 15, 0, 0],
                alignment:"right",
                rowSpan:2
              },
            ],
            [
              {
                text: "ที่อยู่ : 136 ซอยสังคมสงเคราะห์24 แขวงลาดพร้าว เขตลาดพร้าว กรุงเทพมหานคร 10230", bold: true,
                margin: [20, 0, 20, 0],
                colSpan:2
              },
              {}
            ],
            [
              {
                text: [{ text: "เบอร์โทรศัพท์ :  02 956 1048-9 ", bold: true }, { text: "อีเมล : pm@polymer-master.com", bold: true, }],
                margin: [20, 0, 0, 0],
              },
              { qr: path + doc._id, fit: '40', marginTop: 10, marginLeft: 20, alignment:"right" },
            ],
          ]
        }
      },
      {
        layout: "noBorders",
        table: {
          widths: ["*", "*"],
          body: [
            [
              {
                text: name,
                bold: true,
                fontSize: 25,
                alignment: 'left',
                margin: [20, 0, 0, 0],
              },
              {

              }
            ],
            [
              {
                text: [
                  { text: 'NO : ', bold: true },
                  { text: doc.number, }
                ],
                fontSize: 25,
                bold: true,
                margin: [20, -10, 0, 0],
              },
              {
                text: [{text:"วันที่รับคืนเอกสาร : ", bold:true}, new Date(doc.createdAt).toThaiShort()],
                alignment: 'right',
                margin: [0, 0, 20, 0],
              }
            ],
          ]
        }
      },
      {
        layout: "noBorders",
        table: {
          widths: [80, 100, "*", 170],
          body: [
           
            [
              "", "", "",
              {
                text: "วันที่ส่งสินค้า",
                alignment: "right",
                bold: true,
                margin: [0, 0, 20, 0],
              }
            ],
            [
              '',
              '',
              '',
              {
                text: `${new Date(date).toThaiShort()}`,
                alignment: "right", margin: [0, 0, 20, 0]
              }
            ],
          ]
        }
      },
      {
        canvas: [
          {
            type: "line",
            x1: mm(60),
            y1: 5,
            x2: mm(1630),
            y2: 5,
            lineWidth: 0.2,
          },
        ],
      },
    ],
  };
};
let getHeaderDelivery = (
  name: string,
  store: any,
  doc: any,
  path?: string,
  date?: any,
  createName?: string,
  createDate?: any,
) => {
  return {
    fillColor: "#F3F3F3",
    margin: [0, 0, 0, 0],
    stack: [
      {
          //
              // { qr: path + doc._id, fit: '40', marginTop:10, marginLeft:20 },
        layout: "noBorders",
        table: {
          widths: ["*", "*"],
          body: [
            [
              {
                text: "บริษัท โพลีเมอร์ มาสเตอร์ จำกัด",
                bold: true,
                margin: [20, 20, 20, 0],
                alignment: 'left'
              },
              {
                image: store.base64Logo,
                height: 30,
                width: 70,
                margin: [20, 15, 0, 0],
                alignment:"right",
                rowSpan:2
              },
            ],
            [
              {
                text: "ที่อยู่ : 136 ซอยสังคมสงเคราะห์24 แขวงลาดพร้าว เขตลาดพร้าว กรุงเทพมหานคร 10230", bold: true,
                margin: [20, 0, 20, 0],
                colSpan:2
              },
              {}
            ],
            [
              {
                text: [{ text: "เบอร์โทรศัพท์ :  02 956 1048-9 ", bold: true }, { text: "อีเมล : pm@polymer-master.com", bold: true, }],
                margin: [20, 0, 0, 0],
              },
              { qr: path + doc._id, fit: '40', marginTop: 10, marginLeft: 20, alignment:"right" },
            ],
          ]
        }
      },
      {
        layout: "noBorders",
        table: {
          widths: ["*", "*"],
          body: [
            [
              {
                text: name,
                bold: true,
                fontSize: 25,
                alignment: 'left',
                margin: [20, 0, 0, 0],
              },
              {
                text: createName??"",
                bold: true,
                // fontSize: 20,
                alignment: 'right',
                margin: [0, 10, 20, 0],
              }
            ],
            [
              {
                text: [
                  { text: 'NO : ', bold: true },
                  { text: doc.number, }
                ],
                fontSize: 25,
                bold: true,
                margin: [20, -10, 0, 0],
              },
              {
                text: [
                  { text: '', bold: true },
                  { text: `${(new Date(createDate).toThaiShort())??""}`, }
                ],
                // fontSize: 15,
                alignment: 'right',
                bold: true,
                margin: [0, -10, 20, 0],
              }
            ],
           
          ]
        }
      },
      {
        layout: "noBorders",
        table: {
          widths: [80, 100, "*", 170],
          body: [
            
            [
              "", "", "",
              {
                text: "วันที่ส่งสินค้า",
                alignment: "right",
                bold: true,
                margin: [0, 0, 20, 0],
              }
            ],
            [
              '',
              '',
              '',
              {
                text: `${new Date(date).toThaiShort()}`,
                alignment: "right", margin: [0, 0, 20, 0]
              }
            ],
          ]
        }
      },
      {
        canvas: [
          {
            type: "line",
            x1: mm(60),
            y1: 5,
            x2: mm(2300),
            y2: 5,
            lineWidth: 0.2,
          },
        ],
      },
    ],
  };
};
let getHeaderWithdraw = (
  name: string,
  store: any,
  doc: any,
  path?: string,
  date?: any,
) => {
  return {
    fillColor: "#F3F3F3",
    margin: [0, 0, 0, 0],
    stack: [
      {
        layout: "noBorders",
        table: {
          widths: ["*", 280, 80],
          body: [
            [
              { qr: path + doc._id, fit: '40', marginTop: 10, marginLeft: 20 },
              {
                text: "บริษัท โพลีเมอร์ มาสเตอร์ จำกัด",
                bold: true,
                margin: [0, 20, 20, 0],
                alignment: 'right'
              },
              {
                image: store.base64Logo,
                height: 30,
                width: 70,
                margin: [0, 15, 20, 0]
              },
            ],
            [
              {},
              { text: "ที่อยู่ : 136 ซอยสังคมสงเคราะห์24 แขวงลาดพร้าว เขตลาดพร้าว กรุงเทพมหานคร 10230", bold: true, },
              {}
            ],
            [
              {},
              { text: [{ text: "เบอร์โทรศัพท์ :  02 956 1048-9 ", bold: true }, { text: "อีเมล : pm@polymer-master.com", bold: true, }] },
              {}
            ],
          ]
        }
      },
      {
        layout: "noBorders",
        table: {
          widths: ["*"],
          body: [
            [
              {
                text: name,
                bold: true,
                fontSize: 25,
                alignment: 'left',
                margin: [20, 0, 0, 0],
              }
            ],
            [
              {
                text: [
                  { text: 'NO : ', bold: true },
                  { text: doc.number, }
                ],
                bold: true,
                margin: [20, -10, 0, 0],
              }
            ],
          ]
        }
      },
      {
        layout: "noBorders",
        table: {
          widths: [80, 100, "*", 170],
          body: [
            [
              "", "", "",
              {
                text: "วันที่เบิกสินค้า",
                alignment: "right",
                bold: true,
                margin: [0, 0, 20, 0],
              }
            ],
            [
              '',
              '',
              '',
              {
                text: `${new Date(date).toThaiShort()}`,
                alignment: "right", margin: [0, 0, 20, 0]
              }
            ],
          ]
        }
      },
      {
        canvas: [
          {
            type: "line",
            x1: mm(60),
            y1: 5,
            x2: mm(1630),
            y2: 5,
            lineWidth: 0.2,
          },
        ],
      },
    ],
  };
};
let getHeaderNoAddressLanscape = (
  name: string,
  store: any,
  doc: any,
  path?: string,
  date?: any
) => {
  return {
    fillColor: "#F3F3F3",
    margin: [0, 0, 0, 0],
    stack: [
      {
        layout: "noBorders",
        table: {
          widths: ["*", 150, 80],
          body: [
            [
              {
                text: name,
                bold: true,
                fontSize: 25,
                alignment: 'left',
                margin: [20, 10, 0, 0],
              },
              {
                text: "บริษัท โพลีเมอร์ มาสเตอร์ จำกัด",
                bold: true,
                margin: [0, 20, 0, 0]
              },
              {
                image: store.base64Logo,
                height: 30,
                width: 70,
                margin: [0, 15, 20, 0]
              },
            ],
            [
              {
                text: [
                  {
                    text: 'NO : ', bold: true,
                    fontSize: 20,
                  },
                  {
                    text: doc.number,
                    fontSize: 20,
                  }
                ],
                bold: true,
                margin: [20, -10, 0, 0],
              },
              {
                text: "วันที่สร้างเอกสารบนระบบ",
                alignment: "right",
                bold: true,
                margin: [0, 0, 20, 0],
                colSpan:2
              },
              "",
            ],
            [
              
              { qr: path + doc._id, fit: '40', marginBottom: 0, marginLeft: 20 },
              {
                text: `${new Date(date).toThaiShort()}`,
                alignment: "right", margin: [0, 0, 20, 0],
                colSpan:2
              },
              ""
            ]
            
          ]
        }
      },
      // {
      //   layout: "noBorders",
      //   table: {
      //     widths: ["*"],
      //     body: [
      //       [
      //         {
      //           text: "วันที่สร้างเอกสารบนระบบ",
      //           alignment: "right",
      //           bold: true,
      //           margin: [0, 0, 20, 0],
      //         }
      //       ],
      //       [
      //         {
      //           text: `${new Date(date).toThaiShort()}`,
      //           alignment: "right", margin: [0, 0, 20, 0]
      //         }
      //       ],
      //     ]
      //   }
      // },
      {
        canvas: [
          {
            type: "line",
            x1: mm(60),
            y1: 5,
            x2: mm(2300),
            y2: 5,
            lineWidth: 0.2,
          },
        ],
      },
    ],
  };
};






const _ = {
  getHeader,
  getHeaderNoAddress,
  getHeaderUpper,
  getHeaderDelivery,
  getHeaderWithdraw,
  getHeaderNoAddressLanscape,
  getHeaderNoAddressLandscape
};

export default _;