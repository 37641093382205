import WarehouseModel from "../warehouse"

export default class TransactionModel {
  _id: string
  seq:string
  name:string
  lot:string
  keyDate:string
  isActive:boolean
  recordDate:Date
  type:string
  store:string
  customer:string
  project: string 
  note:string
  salesperson:string
  warehouse:string | WarehouseModel
  groupCode:string
  series:string
  lengthText:string
  lengthUnit:string
  qty:number
  condition:number
  code:string
  color:string
  length:string
  amount:number
  group:string
  remark:string
  remark2:string
  paymentDate: Date
  paymentMethod:string
  paymentNote:string
  code2:string
  finishDate:Date
  location:string
  size:string
  unit:string
  address:string
  status:string
  originatedFrom: string
  originatedRef: string
  createdAt: Date
  createdIP: string
  get qtyFormat() {
    try {
      return this.qty.formatComma()
    } catch (error) {
      return "-"
    }
  }
  constructor(jsonData: any) {
    this._id = jsonData._id
    this.seq = jsonData.seq
    this.name = jsonData.name
    this.lot = jsonData.lot??"-"
    this.keyDate = jsonData.keyDate
    this.isActive = jsonData.isActive
    this.recordDate = new Date(jsonData.recordDate)
    this.type = jsonData.type
    this.store = jsonData.store
    this.customer = jsonData.customer
    this.project = jsonData.project
    this.note = jsonData.note
    this.salesperson = jsonData.salesperson
    this.warehouse = jsonData.warehouse
    this.groupCode = jsonData.groupCode
    this.series = jsonData.series
    this.lengthText = jsonData.lengthText
    this.lengthUnit = jsonData.lengthUnit
    this.qty = jsonData.qty
    this.condition = jsonData.condition
    this.code = jsonData.code
    this.color = jsonData.color
    this.length = jsonData.length
    this.amount = jsonData.amount
    this.group = jsonData.group
    this.remark = jsonData.remark
    this.remark2 = jsonData.remark2
    this.paymentDate = new Date(jsonData.paymentDate)
    this.paymentMethod = jsonData.paymentMethod
    this.paymentNote = jsonData.paymentNote
    this.code2 = jsonData.code2
    this.finishDate = new Date(jsonData.finishDate)
    this.location = jsonData.location
    this.size = jsonData.size
    this.unit = jsonData.unit
    this.address = jsonData.address
    this.status = jsonData.status ?? "-"
    this.originatedFrom = jsonData.originatedFrom
    this.originatedRef = jsonData.originatedRef
    this.createdAt = new Date(jsonData.createdAt)
    this.createdIP = jsonData.createdIP
  }
  toJSON() {
    let obj: any = Object.assign({},this)
    return obj
  }
}