import 'reflect-metadata';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vSelect from 'vue-select'


import "@/extensions/date.extension";
import "@/extensions/string.extension";
import "@/extensions/number.extension";

// import "@/scss/_colors.scss"
// import "bootstrap/scss/bootstrap.scss";
import "@fortawesome/fontawesome-pro/css/all.css";
import "@popperjs/core";
import 'vue-select/dist/vue-select.css';
import "../node_modules/bootstrap/dist/js/bootstrap";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "@yaireo/tagify/src/tagify.scss"
import SideNavigation from "@/components/SideNavigation.vue";
import PRCalendar from "@/components/PRCalendar.vue";
import PRPaginate from "@/components/PRPaginate.vue";
import PRTagify from "@/components/PRTagify.vue";
import PRStatus from "@/components/PRStatus.vue";
import PRLoading from "@/components/PRLoading.vue";
import PRTableTools from "@/components/PRTableTools.vue";
// import PRUploadImages from "@/components/PRUploadImages.vue";

const app = createApp(App)
  .use(store)
  .use(Toast)
  .use(router)
  app.component('v-select', vSelect)
  app.component("SideNavigation", SideNavigation);
  app.component("PRPaginate", PRPaginate);
  app.component("PRTagify", PRTagify);
  app.component("PRCalendar", PRCalendar);
  app.component("PRStatus", PRStatus);
  app.component("PRLoading", PRLoading);
  app.component("PRTableTools", PRTableTools);
  // app.component("PRUploadImages", PRUploadImages);


app.mount('#app')