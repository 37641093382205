import ADDRESS from "@/setting";
import axios from "axios"
import ReportModel,{ReportB01Model,ReportB02Model,ReportB03Model,ReportA01ItemsModel,ReportA02Model,ReportA03Model,ReportB04Model,ReportB05Model,ReportB06Model} from "@/model/report";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";


export default class ReportServices {
  public getReportA01(query: any) {
    return new Promise<Array<ReportA01ItemsModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/report/A01`, query);
      
      let docs: Array<ReportA01ItemsModel> = response.data.items.map((el: any) => new ReportA01ItemsModel(el))
      resolve(docs)
    })
  }
  public getReportA02(query: any) {
    return new Promise<ReportA02Model>(async (resolve, reject) => {
      
      let response = await axios.post(`${ADDRESS}/report/A02`, query);
      
      let doc: ReportA02Model = response.data
      resolve(doc)
    })
  }
  public getReportA03(query: any) {
    return new Promise<ReportA03Model>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/report/A03`, query);
      
      let doc: ReportA03Model = response.data
      resolve(doc)
    })
  }
  public getReportB04(query: any) {
    return new Promise<ReportB04Model>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/report/B04`, query);
      
      let doc: ReportB04Model = response.data
      resolve(doc)
    })
  }
  public getReportB05(query: any) {
    return new Promise<ReportB05Model>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/report/B05`, query);
      
      let doc: ReportB05Model = response.data
      resolve(doc)
    })
  }
  public getReportB06(query: any) {
    return new Promise<ReportB06Model>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/report/B06`, query);
      
      let doc: ReportB06Model = response.data
      resolve(doc)
    })
  }

  public getReportB01(query: any) {
    return new Promise<ReportB01Model>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/report/B01`, query);
      
      let doc: ReportB01Model = response.data
       resolve(doc)
    })
    
  }
  public getReportB02(query: any) {
    return new Promise<ReportB02Model>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/report/B02`, query);
      
      let doc: ReportB02Model = response.data
       resolve(doc)
    })
  }
  public getReportB03(query: any) {
    return new Promise<ReportB03Model>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/report/B03`, query);
      
      let doc: ReportB03Model = response.data
       resolve(doc)
    })
  }

}
