import { createStore, storeKey } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
export default createStore({
  state: {
    PDFFonts: {
      Roboto: {
        normal: "https://polymer-master.net/api/v1/static/sarabun_regular.ttf",
        bold: "https://polymer-master.net/api/v1/static/sarabun_bold.ttf",
        italics: "https://polymer-master.net/api/v1/static/sarabun_italic.ttf",
        bolditalics:
          "https://polymer-master.net/api/v1/static/sarabun_bolditalic.ttf",
      },
      Prompt: {
        normal: "https://polymer-master.net/api/v1/static/prompt-regular.ttf",
        bold: "https://polymer-master.net/api/v1/static/prompt-bold.ttf",
        italics: "https://polymer-master.net/api/v1/static/prompt-italic.ttf",
        bolditalics:
          "https://polymer-master.net/api/v1/static/prompt-boldItalic.ttf",
      },
      FontAwesome: {
        normal:
          // "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-solid-900.ttf",
          "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/webfonts/fa-regular-400.ttf"
      },
    },
    userIsLogin: false,
    base64Logo: require("./logo").imgPLM,
    base64LogoTC: require("./logo").treeconcept,
    base64LogoST: require("./logo").starke,
    base64LogoWL: require("./logo").wallsolution,
    base64LogoTFM: require("./logo").theframe,
    PDFStyles: {
      fontawesome: {
        font: "FontAwesome",
        fontSize: 12,
        color: "#656565",
      },
      pagetitle: {
        fontSize: 26,
        bold: true,
      },
      header: {
        fontSize: 16,
        bold: true,
      },
      title: {
        fontSize: 16,
        bold: true,
      },
      subtitle: {
        fontSize: 12,
        color: "#555555",
      },
      body: {
        fontSize: 12,
      },
      bold: {
        bold: true,
      },
      boldunderline: {
        decoration: "underline",
        bold: true,
        decorationStyle: "solid",
        decorationColor: "black",
      },
      underline: {
        decoration: "underline",
        decorationStyle: "dotted",
        decorationColor: "black",
      },
      underlineblank: {
        decoration: "underline",
        decorationStyle: "dotted",
        decorationColor: "black",
        color: "#FFFFFF",
      },
      blank: {
        decoration: "underline",
        decorationStyle: "dotted",
        decorationColor: "#FFFFFF",
        color: "#FFFFFF",
      },
      signature: {
        fontSize: 20,
        bold: true,
        decoration: "overline",
        decorationStyle: "solid",
        decorationColor: "black",
      },
    },
    cart: [],
    formDetail: {},
    projects: [],
    customers: [],
    warehouses: [],

  },
  getters: {
    cart: (state) => {
      const arrayFailed = Object.entries(state.cart).map((arr) => ({
        ...(arr[1] as any),
      }));
      //@ts-ignore
      return arrayFailed
    }
  },
  mutations: {
    login: (state, payload) => {
      state.userIsLogin = true
    },
    logout: (state, payload) => {
      state.userIsLogin = false
    },
    setCartItem: (state, payload) => {
      if (Array.isArray(payload))
        //@ts-ignore
        state.cart = Object.assign([], payload)
      else {
        try {
          const arrayFailed = Object.entries(payload).map((arr) => ({
            ...(arr[1] as any),
          }));
          console.log({arrayFailed})
          //@ts-ignore
          state.cart = arrayFailed
        } catch (error) {
          state.cart = []
        }
      }
    },
    setFormDetail: (state, payload) => {
      state.formDetail = Object.assign({}, payload)
    },
    setCustomers: (state, payload) => {
      if (Array.isArray(payload))
        //@ts-ignore
        state.customers = Object.assign([], payload)
      else {
        try {
          const arrayFailed = Object.entries(payload).map((arr) => ({
            ...(arr[1] as any),
          }));
          //@ts-ignore
          state.customers = arrayFailed
        } catch (error) {
          state.customers = []
        }
      }
    },
    setProjects: (state, payload) => {
      if (Array.isArray(payload))
        //@ts-ignore
        state.projects = Object.assign([], payload)
      else {
        try {
          const arrayFailed = Object.entries(payload).map((arr) => ({
            ...(arr[1] as any),
          }));
          //@ts-ignore
          state.projects = arrayFailed
        } catch (error) {
          state.projects = []
        }
      }
    },
    setWarehouses: (state, payload) => {
      if (Array.isArray(payload))
        //@ts-ignore
        state.warehouses = Object.assign([], payload)
      else {
        try {
          const arrayFailed = Object.entries(payload).map((arr) => ({
            ...(arr[1] as any),
          }));
          //@ts-ignore
          state.warehouses = arrayFailed
        } catch (error) {
          state.warehouses = []
        }
      }
      state.warehouses = state.warehouses.filter((item:any)=>item.isActive)
    },




    // updateOrderDetail: (state, payload) => {
    //   state.orderDetail = payload
    // },
    // addOrderItem: (state, payload) => {
    //   //@ts-ignore
    //   state.orderCart.push(payload)
    // },
    // setOrderItem: (state, payload) => {
    //   //@ts-ignore
    //   state.orderCart = payload
    // },
    // removeOrderItem: (state, payload) => {
    //   state.orderCart.splice(payload, 1)
    // },
    // //
    // updateDeliveryDetail: (state, payload) => {
    //   state.deliveryDetail = payload
    // },
    // addDeliveryItem: (state, payload) => {
    //   //@ts-ignore
    //   state.deliveryCart.push(payload)
    // },
    // setDeliveryItem: (state, payload) => {
    //   //@ts-ignore
    //   state.deliveryCart = payload
    // },
    // removeDeliveryItem: (state, payload) => {
    //   state.deliveryCart.splice(payload, 1)
    // },
    // //
    // updateReceiveDetail: (state, payload) => {
    //   state.receiveDetail = payload
    // },
    // addReceiveItem: (state, payload) => {
    //   //@ts-ignore
    //   state.receiveCart.push(payload)
    // },
    // setReceiveItem: (state, payload) => {
    //   //@ts-ignore
    //   state.receiveCart = payload
    // },
    // removeReceiveItem: (state, payload) => {
    //   state.receiveCart.splice(payload, 1)
    // },
    // //
    // updateRefundDetail: (state, payload) => {
    //   state.refundDetail = payload
    // },
    // addRefundItem: (state, payload) => {
    //   //@ts-ignore
    //   state.refundCart.push(payload)
    // },
    // setRefundItem: (state, payload) => {
    //   //@ts-ignore
    //   state.refundCart = payload
    // },
    // removeRefundItem: (state, payload) => {
    //   state.refundCart.splice(payload, 1)
    // },
    // clearRefundItem: (state, payload) => {
    //   state.refundCart = []
    // },
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      key: "PLM221WF1",
      paths: ["userIsLogin"]
    })
  ]
})
