import ProductModel from "../product"
import WarehouseModel from "../warehouse"

export default class RefundModel {
  _id?: string
  quotationNumber: Array<string>
  number: string
  sequence: string
  customer: string
  remark: string
  returnDate: Date
  status: string
  referencesNumber: string
  refund: string
  sales: string
  project: string
  address1: string
  address2: string
  items:Array<RefundItemModel>
  createdAt: Date
  createdIP: string
  modifiedAt: Date
  modifiedIP: string

  get quotations() {
    try {
      return this.quotationNumber.join(", ")
    } catch (error) {
      return "-"
    }
  }
  constructor(jsonData: any) {
    this._id = jsonData._id
    this.quotationNumber = jsonData.quotationNumber??""
    this.number = jsonData.number??""
    this.customer = jsonData.customer??""
    this.remark = jsonData.remark??""
    this.returnDate = new Date(jsonData.returnDate)
    this.status = jsonData.status??""
    this.referencesNumber = jsonData.referencesNumber??""
    this.refund = jsonData.refund??""
    this.sales = jsonData.sales??"-"
    this.project = jsonData.project??""
    this.customer = jsonData.customer??""
    this.address1 = jsonData.address1??""
    this.address2 = jsonData.address2??""
    this.sequence = jsonData.sequence??""
    this.items = (jsonData.items??[]).map((m:any)=>new RefundItemModel(m));
    this.createdAt = new Date(jsonData.createdAt)
    this.createdIP = jsonData.createdIP??""
    this.modifiedAt = new Date(jsonData.modifiedAt)
    this.modifiedIP = jsonData.modifiedIP??""
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return obj
  }
}
export class RefundItemModel {
  _id?: string
  product: string|ProductModel
  model: string
  color: string
  code: string
  lot: string
  detail: string
  warehouse: string|WarehouseModel
  warehouseName: string
  series: string
  qty: string
  unit: string
  note: string

  constructor(jsonData: any){
    this._id = jsonData._id
    this.product = jsonData.product??""
    this.model = jsonData.model??""
    this.color = jsonData.color??""
    this.code = jsonData.code??""
    this.series = jsonData.series??""
    this.lot = jsonData.lot??"";
    this.detail = jsonData.detail??""
    this.warehouse = jsonData.warehouse??""
    this.warehouseName = jsonData.warehouseName??""
    this.qty = jsonData.qty??""
    this.unit = jsonData.unit??""
    this.note = jsonData.note??""
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return obj
  }
}