import { PaginateResponse } from "@/common";
import CartModel from "@/model/cart";
import ProductModel from "@/model/product";
import { ReceiveItemModel } from "@/model/receive";
import { OrderItemModel } from "@/model/order";
import { TransferItemModel } from "@/model/transfer";

import CartServices from "@/service/cart";
import { DateTime } from "luxon";
import { Service } from 'typedi';
import { DeliveryItemModel } from "@/model/delivery";
import { AdjustmentItemModel } from "@/model/adjustment";

@Service()
export class ActiveCartSingleClass {
  data: Array<CartModel> = [];
  isReturn: boolean = false;
  loading: boolean = false;
  addItemToCart(product: CartModel) {
    let prep = product
    prep.product = product._id
    this.data.push(prep);
  }
  removeItemFromCart(index: number) {
    this.data.splice(index, 1)
  }

  convertToReceiveItems() {
    return this.data.map(item=>new ReceiveItemModel(item))
  }
  convertFromReceiveItems(items:Array<ReceiveItemModel>){
    this.data = items.map(item=>new CartModel(item))
  }
  //
  convertToOrderItems() {
    return this.data.map(item=>new OrderItemModel(item))
  }
  convertFromOrderItems(items:Array<OrderItemModel>){
    this.data = items.map(item=>new CartModel(item))
  }
  //
  convertToDeliveryItems() {
    return this.data.map(item=>new DeliveryItemModel(item))
  }
  convertFromDeliveryItems(items:Array<DeliveryItemModel>){
    this.data = items.map(item=>new CartModel(item))
  }
  //
  convertToTransferItems() {
    return this.data.map(item=>new TransferItemModel(item))
  }
  convertFromTransferItems(items:Array<TransferItemModel>){
    this.data = items.map(item=>new CartModel(item))
  }
  //
  convertToAdjustmentItems() {
    return this.data.map(item=>new AdjustmentItemModel(item))
  }
  convertFromAdjustmentItems(items:Array<AdjustmentItemModel>){
    this.data = items.map(item=>new CartModel(item))
  }

  convertToRefundItems() {
    return this.data
  }
  convertToRequisitionItems() {
    return this.data
  }

}
