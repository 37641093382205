import { PaginateResponse } from "@/common";
import ProjectModel from "@/model/project";
import ProjectServices from "@/service/project";
import { DateTime } from "luxon";
import { Service } from 'typedi';

@Service()
export class ActiveProjectSingleClass {
  data?: ProjectModel;
  loading: boolean = true;
  async getProjectSingle(id: string) {
    this.loading = true
    this.data = await new ProjectServices().getProjectSingle(id)
    this.loading = false
  }
  async updateProjectSingle() {
    this.loading = true
    this.data = await new ProjectServices().updateProject(this.data!._id!, this.data!)
    this.data = await new ProjectServices().getProjectSingle(this.data!._id!)
    this.loading = false
  }
  async createProjectSingle() {
    this.loading = true
    this.data = await new ProjectServices().createProject(this.data!)
    this.loading = false
  }
  async deleteProjectSingle(id: string) {
    this.loading = true
    this.data = await new ProjectServices().deleteProjectSingle(id)
    this.loading = false
  }
}

@Service()
export class ActiveProjectListClass {
  data?: PaginateResponse<ProjectModel>;
  loading: boolean = true;

  async getProjectList(query: any) {
    this.loading = true
    this.data = await new ProjectServices().getProjectList(query)
    this.loading = false
  }

  async deleteProjectFromList(id: string) {
    this.loading = true
    let tmp = await new ProjectServices().deleteProjectSingle(id)
    let index = this.data!.docs.findIndex(doc => doc._id === id)
    this.data!.docs.splice(index, 1)
    this.loading = false
  }

}