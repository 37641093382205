export default class SettingModel {
  _id: string
  number: string
  name: string
  category: string
  sequence: string
  member: Array<SettingMemberModel>
  createdAt: Date
  createdIP: string
  modifiedAt: Date
  modifiedIP: string
  constructor(jsonData: any) {
    this._id = jsonData._id
    this.number = jsonData.number
    this.name = jsonData.name
    this.category = jsonData.category
    this.sequence = jsonData.sequence
    this.member = (jsonData.member??[]).map((item:any)=>new SettingMemberModel(item))
    this.createdAt = new Date(jsonData.createdAt)
    this.createdIP = jsonData.createdIP
    this.modifiedAt = new Date(jsonData.modifiedAt)
    this.modifiedIP = jsonData.modifiedIP
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return obj
  }
}
export class SettingMemberModel {
  id: string
  name: string
  order: string
  code: string
  slug: string
  description: string
  text1: string
  text2:string
  text3:string
  number1: number 
  number2: number 
  number3: number 
  constructor(jsonData: any) {
    this.id = jsonData.id
    this.name = jsonData.name
    this.order = jsonData.order
    this.code = jsonData.code
    this.slug = jsonData.slug
    this.description = jsonData.description
    this.text1 = jsonData.text1
    this.text2 = jsonData.text2
    this.text3 = jsonData.text3
    this.number1 = jsonData.number1
    this.number2 = jsonData.number2
    this.number3 = jsonData.number3

    
  }
}