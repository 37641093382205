import ProductModel from "../product"

export default class CartModel extends ProductModel {
  product?: string
  qty: number
  unit: string
  warehouse: string
  warehouseName: string
  lot: string
  // For Order Cart
  area: string
  note: string
  // For Receive Cart
  packingListCode?: string
  packingList?: { pcsPerPkg: number, pkg: number, pcs: number, remark: string }
  checkList?: { pcsPerPkg: number, pkg: number, pcs: number, remark: string }
  // For Transfer Cart
  size?: string
  constructor(jsonData: any) {
    super(jsonData)
    this.product = jsonData.product
    this.qty = jsonData.qty
    this.unit = jsonData.unit
    this.warehouse = jsonData.warehouse
    this.warehouseName = jsonData.warehouseName
    this.lot = jsonData.lot
    // For Order Cart
    this.area = jsonData.area
    this.note = jsonData.note
    // For Receive Cart
    this.packingListCode = jsonData.packingListCode
    this.packingList = jsonData.packingList
    this.checkList = jsonData.checkList
    // For Transfer Cart
    this.color = jsonData.color
    this.size = jsonData.size
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return obj
  }
}