import { PaginateResponse } from "@/common";
import AdjustmentModel from "@/model/adjustment";
import AdjustmentServices from "@/service/adjustment";
import { DateTime } from "luxon";
import { Service } from 'typedi';

@Service()
export class ActiveAdjustmentSingleClass {
  data?: AdjustmentModel;
  loading: boolean = true;
  async getAdjustmentSingle(id: string) {
    this.loading = true
    this.data = await new AdjustmentServices().getAdjustmentSingle(id)
    this.loading = false
  }
  async updateAdjustmentSingle() {
    this.loading = true
    this.data = await new AdjustmentServices().updateAdjustment(this.data!._id!, this.data!)
    this.data = await new AdjustmentServices().getAdjustmentSingle(this.data!._id!)
    this.loading = false
  }
  async createAdjustmentSingle() {
    this.loading = true
    this.data = await new AdjustmentServices().createAdjustment(this.data!)
    this.loading = false
  }
  async deleteAdjustmentSingle(id: string) {
    this.loading = true
    this.data = await new AdjustmentServices().deleteAdjustmentSingle(id)
    this.loading = false
  }
}

@Service()
export class ActiveAdjustmentListClass {
  data?: PaginateResponse<AdjustmentModel>;
  loading: boolean = true;

  async getAdjustmentList(query: any) {
    this.loading = true
    this.data = await new AdjustmentServices().getAdjustmentList(query)
    this.loading = false
  }

  async deleteAdjustmentFromList(id: string) {
    this.loading = true
    let tmp = await new AdjustmentServices().deleteAdjustmentSingle(id)
    let index = this.data!.docs.findIndex(doc => doc._id === id)
    this.data!.docs.splice(index, 1)
    this.loading = false
  }

}