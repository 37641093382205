import { PaginateResponse } from "@/common";
import WarehouseModel from "@/model/warehouse";
import WarehouseServices from "@/service/warehouse";
import { DateTime } from "luxon";
import { Service } from 'typedi';

@Service()
export class ActiveWarehouseSingleClass {
  data?: WarehouseModel;
  loading: boolean = true;
  async getWarehouseSingle(id: string) {
    this.loading = true
    this.data = await new WarehouseServices().getWarehouseSingle(id)
    this.loading = false
  }
  async updateWarehouseSingle() {
    this.loading = true
    this.data = await new WarehouseServices().updateWarehouse(this.data!._id!, this.data!)
    this.data = await new WarehouseServices().getWarehouseSingle(this.data!._id!)
    this.loading = false
  }
  async createWarehouseSingle() {
    this.loading = true
    this.data = await new WarehouseServices().createWarehouse(this.data!)
    this.loading = false
  }
  async deleteWarehouseSingle(id: string) {
    this.loading = true
    this.data = await new WarehouseServices().deleteWarehouseSingle(id)
    this.loading = false
  }
}

@Service()
export class ActiveWarehouseListClass {
  data?: PaginateResponse<WarehouseModel>;
  loading: boolean = true;

  async getWarehouseList(query: any) {
    this.loading = true
    this.data = await new WarehouseServices().getWarehouseList(query)
    this.loading = false
  }

  async deleteWarehouseFromList(id: string) {
    this.loading = true
    let tmp = await new WarehouseServices().deleteWarehouseSingle(id)
    let index = this.data!.docs.findIndex(doc => doc._id === id)
    this.data!.docs.splice(index, 1)
    this.loading = false
  }

}