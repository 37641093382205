import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import wip from '@/views/WIP.vue'

import orderMain from '@/views/order/Main.vue'
import orderList from '@/views/order/List.vue'
import orderApproval from '@/views/order/Approval.vue'
import orderVerify from '@/views/order/Verify.vue'
import orderForm from '@/views/order/Form.vue'
import product from '@/views/cart/Product.vue'
import orderPDF from '@/views/order/PDF.vue'
//
import deliveryMain from '@/views/delivery/Main.vue'
import deliveryList from '@/views/delivery/List.vue'
import deliveryApproval from '@/views/delivery/Approval.vue'
import deliveryForm from '@/views/delivery/Form.vue'
import deliveryPDF from '@/views/delivery/PDFDelivery.vue'
import withDrawPDF from '@/views/delivery/PDFWithdraw.vue'
import convert from '@/views/delivery/Convert.vue'
//
import settingMain from '@/views/setting/Main.vue'
import settingList from '@/views/setting/List.vue'
import settingForm from '@/views/setting/Form.vue'
//
import refundMain from '@/views/refund/Main.vue'
import refundList from '@/views/refund/List.vue'
import refundForm from '@/views/refund/Form.vue'
import refundPDF from '@/views/refund/PDF.vue'
//
import receiveMain from '@/views/receive/Main.vue'
import receiveList from '@/views/receive/List.vue'
import receiveForm from '@/views/receive/Form.vue'
import receivePDF from '@/views/receive/PDF.vue'
//
import transferMain from '@/views/transfer/Main.vue'
import transferList from '@/views/transfer/List.vue'
import transferForm from '@/views/transfer/Form.vue'
import transferPDF from '@/views/transfer/PDF.vue'
//
import adjustmentMain from '@/views/adjustment/Main.vue'
import adjustmentList from '@/views/adjustment/List.vue'
import adjustmentForm from '@/views/adjustment/Form.vue'
import adjustmentPDF from '@/views/adjustment/PDF.vue'
//
import transactionMain from '@/views/transaction/Main.vue'
import transactionList from '@/views/transaction/List.vue'
import transactionForm from '@/views/transaction/Form.vue'
//
import projectMain from '@/views/transfer/Main.vue'
import projectList from '@/views/project/List.vue'
import projectForm from '@/views/project/Form.vue'
//
import dashboard from '@/views/Dashboard.vue'
//
import customerMain from '@/views/customer/Main.vue'
import customerList from '@/views/customer/List.vue'
import customerForm from '@/views/customer/Form.vue'
//
import productMain from '@/views/product/Main.vue'
import productList from '@/views/product/List.vue'
import productForm from '@/views/product/Form.vue'
//
import warehouseMain from '@/views/warehouse/Main.vue'
import warehouseList from '@/views/warehouse/List.vue'
import warehouseForm from '@/views/warehouse/Form.vue'
//
import reportMain from '@/views/report/Main.vue'
import reportList from '@/views/report/List.vue'
import reportB01 from '@/views/report/ReportB01.vue'
import reportB02 from '@/views/report/ReportB02.vue'
import reportB03 from '@/views/report/ReportB03.vue'
import reportB04 from '@/views/report/ReportB04.vue'
import reportB05 from '@/views/report/ReportB05.vue'
import reportB06 from '@/views/report/ReportB06.vue'
import reportA01 from '@/views/report/ReportA01.vue'
import reportA02 from '@/views/report/ReportA02.vue'
import reportA03 from '@/views/report/ReportA03.vue'



const routes: Array<RouteRecordRaw> = [
  {
    path: 'dashboard',
    name: 'dashboard',
    component: dashboard,
  },
  {
    path: 'receive',
    name: 'receive Main',
    component: receiveMain,
    children: [
      {
        path: '',
        name: 'receive List',
        component: receiveList
      },
      {
        path: 'create',
        name: 'receive Form',
        component: receiveForm
      },
      {
        path: 'edit/:id',
        name: 'receive Edit',
        component: receiveForm
      },
      {
        path: 'verify/:id',
        name: 'receive Verify',
        component: receiveForm
      },
      {
        path: 'pdf/:id',
        name: 'receive PDF',
        component: receivePDF
      },
    ]
  },
  {
    path: 'warehouse',
    name: 'warehouse Main',
    component: warehouseMain,
    children: [
      {
        path: '',
        name: 'warehouse List',
        component: warehouseList
      },
      {
        path: 'create',
        name: 'warehouse Form',
        component: warehouseForm
      },
      {
        path: 'edit/:id',
        name: 'warehouse Edit',
        component: warehouseForm
      }
    ]
  },
  {
    path: 'transaction',
    name: 'transaction Main',
    component: transactionMain,
    children: [
      {
        path: '',
        name: 'transaction List',
        component: transactionList
      },
      {
        path: 'create',
        name: 'transaction Form',
        component: transactionForm
      },
      {
        path: 'edit/:id',
        name: 'transaction Edit',
        component: transactionForm
      }
    ]
  },
  {
    path: 'cart',
    name: 'Cart',
    component: product
  },
  {
    path: 'order',
    name: 'order Main',
    component: orderMain,
    children: [
      {
        path: '',
        name: 'order List',
        component: orderList
      },
      {
        path: 'approval',
        name: 'order Approval',
        component: orderApproval
      },
      {
        path: 'verify',
        name: 'order Verify',
        component: orderVerify
      },
      {
        path: 'create',
        name: 'order Form',
        component: orderForm
      },
      {
        path: 'edit/:id',
        name: 'order Edit',
        component: orderForm
      },
      {
        path: 'pdf/:id',
        name: 'order PDF',
        component: orderPDF
      },
    ]
  },
  {
    path: 'delivery',
    name: 'delivery Main',
    component: deliveryMain,
    children: [
      {
        path: '',
        name: 'Delivery List',
        component: deliveryList
      },
      {
        path: 'approval',
        name: 'delivery Approval',
        component: deliveryApproval
      },
      {
        path: 'create',
        name: 'Delivery Form',
        component: deliveryForm
      },
      {
        path: 'edit/:id',
        name: 'Delivery Edit',
        component: deliveryForm
      },
      {
        path: 'convert/:id',
        name: 'Convert',
        component: convert
      },
      {
        path: 'convert/:id/:delivery_id',
        name: 'Convert Edit',
        component: convert
      },
      {
        path: 'pdf/:id',
        name: 'delivery PDF',
        component: deliveryPDF
      },
      
    ]
  },
  {
    path: '/portal/withdraw/edit/:id',
    name: 'withDraw Edit',
    component: deliveryForm
  },
  {
    path: '/portal/withdraw/pdf/:id',
    name: 'withDraw PDF',
    component: withDrawPDF
  },
  {
    path: 'refund',
    name: 'refund Main',
    component: refundMain,
    children: [
      {
        path: '',
        name: 'refund List',
        component: refundList
      },
      {
        path: 'create',
        name: 'refund Form',
        component: refundForm
      },
      {
        path: 'edit/:id',
        name: 'refund Edit',
        component: refundForm
      },
      {
        path: 'pdf/:id',
        name: 'refund PDF',
        component: refundPDF
      },
    ]
  },
  {
    path: 'transfer',
    name: 'transfer Main',
    component: transferMain,
    children: [
      {
        path: '',
        name: 'transfer List',
        component: transferList
      },
      {
        path: 'create',
        name: 'transfer Form',
        component: transferForm
      },
      {
        path: 'edit/:id',
        name: 'transfer Edit',
        component: transferForm
      },
      {
        path: 'pdf/:id',
        name: 'transfer PDF',
        component: transferPDF
      }
    ]
  },
  {
    path: 'adjustment',
    name: 'adjustment Main',
    component: adjustmentMain,
    children: [
      {
        path: '',
        name: 'adjustment List',
        component: adjustmentList
      },
      {
        path: 'create',
        name: 'adjustment Form',
        component: adjustmentForm
      },
      {
        path: 'edit/:id',
        name: 'adjustment Edit',
        component: adjustmentForm
      },
      {
        path: 'pdf/:id',
        name: 'adjustment PDF',
        component: adjustmentPDF
      }
    ]
  },
  {
    path: 'product',
    name: 'product Main',
    component: productMain,
    children: [
      {
        path: '',
        name: 'product List',
        component: productList
      },
      {
        path: 'create',
        name: 'product Form',
        component: productForm
      },
      {
        path: 'edit/:id',
        name: 'product Edit',
        component: productForm
      }
    ]
  },
  {
    path: 'project',
    name: 'project Main',
    component: projectMain,
    children: [
      {
        path: '',
        name: 'project List',
        component: projectList
      },
      {
        path: 'create',
        name: 'project Form',
        component: projectForm
      },
      {
        path: 'edit/:id',
        name: 'project Edit',
        component: projectForm
      },
      {
        path: 'detail/:id',
        name: 'project Detail',
        component: projectForm
      }
    ]
  },
  {
    path: 'customer',
    name: 'customer Main',
    component: customerMain,
    children: [
      {
        path: '',
        name: 'customer List',
        component: customerList
      },
      {
        path: 'create',
        name: 'customer Form',
        component: customerForm
      },
      {
        path: 'edit/:id',
        name: 'customer Edit',
        component: customerForm
      }, {
        path: 'detail/:id',
        name: 'customer Detail',
        component: customerForm
      }
    ]
  },
  {
    path: 'setting',
    name: 'setting Main',
    component: settingMain,
    children: [
      {
        path: '',
        name: 'setting List',
        component: settingList
      },
      {
        path: 'create',
        name: 'setting Form',
        component: settingForm
      },
      {
        path: 'edit/:id',
        name: 'setting Edit',
        component: settingForm
      }
    ]
  },
  {
    path: 'report',
    name: 'report Main',
    component: reportMain,
    children: [
      {
        path: '',
        name: 'report List',
        component: reportList
      },
      {
        path: 'B01',
        name: 'report B01',
        component: reportB01
      },
      {
        path: 'B02',
        name: 'report B02',
        component: reportB02
      },
      {
        path: 'B03',
        name: 'report B03',
        component: reportB03
      },

      {
        path: 'B04',
        name: 'report B04',
        component: reportB04
      },

      {
        path: 'B05',
        name: 'report B05',
        component: reportB05
      },
      {
        path: 'B06',
        name: 'report B06',
        component: reportB06
      },
      {
        path: 'A01',
        name: 'report A01',
        component: reportA01
      },
    
      {
        path: 'A02',
        name: 'report A02',
        component: reportA02
      },
      {
        path: 'A03',
        name: 'report A03',
        component: reportA03
      },
    ]
  },
  // {
  //   path: 'requisition',
  //   name: 'requisition Main',
  //   component: requisitionMain,
  //   children: [
  //     {
  //       path: '',
  //       name: 'requisition List',
  //       component: requisitionList
  //     },
  //     {
  //       path: 'create',
  //       name: 'requisition Form',
  //       component: requisitionForm
  //     },
  //     {
  //       path: 'edit/:id',
  //       name: 'requisition Edit',
  //       component: requisitionForm
  //     }
  //   ]
  // },

  // {
  //   path: '',
  //   name: 'home portal',
  //   component: dashboard,
  // },
  // {
  //   path: 'wip',
  //   name: 'Work In Progress',
  //   component: wip,
  // },
]
export default routes