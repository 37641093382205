import { PaginateResponse } from "@/common";
import OrderModel from "@/model/order";
import OrderServices from "@/service/order";
import { DateTime } from "luxon";
import { Service } from 'typedi';

@Service()
export class ActiveOrderSingleClass {
  data?: OrderModel;
  loading: boolean = true;
  async getOrderSingle(id: string) {
    this.loading = true
    this.data = await new OrderServices().getOrderSingle(id)
    this.loading = false
  }
  async updateOrderSingle() {
    this.loading = true
    this.data = await new OrderServices().updateOrder(this.data!._id!, this.data!)
    this.data = await new OrderServices().getOrderSingle(this.data!._id!)
    this.loading = false
  }
  async createOrderSingle() {
    this.loading = true
    this.data = await new OrderServices().createOrder(this.data!)
    this.loading = false
  }
  async deleteOrderSingle(id: string) {
    this.loading = true
    this.data = await new OrderServices().deleteOrderSingle(id)
    this.loading = false
  }
  async duplicateTC() {
    let result = await new OrderServices().duplicateTC(this.data!)
    return result
  }
}

@Service()
export class ActiveOrderListClass {
  data?: PaginateResponse<OrderModel>;
  loading: boolean = true;

  async getOrderList(query: any) {
    this.loading = true
    this.data = await new OrderServices().getOrderList(query)
    this.loading = false
  }

  async deleteOrderFromList(id: string) {
    this.loading = true
    let tmp = await new OrderServices().deleteOrderSingle(id)
    let index = this.data!.docs.findIndex(doc => doc._id === id)
    this.data!.docs.splice(index, 1)
    this.loading = false
  }

}