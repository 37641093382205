import { DocumentStatus } from "@/model/dashboard";
import DashboardServices from "@/service/dashboard";
import SettingServices from "@/service/dashboard";
import { Service } from 'typedi';

@Service()
export class ActiveDashboardDocument {
  orders!: Array<DocumentStatus>
  orders_loading: boolean = true;
  receives!: Array<DocumentStatus>
  receives_loading: boolean = true;
  deliveries!: Array<DocumentStatus>
  deliveries_loading: boolean = true;
  transfers!: Array<DocumentStatus>
  transfers_loading: boolean = true;
  refunds!: Array<DocumentStatus>
  refunds_loading: boolean = true;
  adjustments!: Array<DocumentStatus>
  adjustments_loading: boolean = true;
  async getOrderSingle() {
    this.orders_loading = true
    this.orders = await new DashboardServices().getOrderStatus()
    this.orders_loading = false
  }
  async getReceiveSingle() {
    this.receives_loading = true
    this.receives = await new DashboardServices().getReceiveStatus()
    this.receives_loading = false
  }
  async getDeliverySingle() {
    this.deliveries_loading = true
    this.deliveries = await new DashboardServices().getDeliveryStatus()
    this.deliveries_loading = false
  }
  async getTransferSingle() {
    this.transfers_loading = true
    this.transfers = await new DashboardServices().getTransferStatus()
    this.transfers_loading = false
  }
  async getRefundSingle() {
    this.refunds_loading = true
    this.refunds = await new DashboardServices().getRefundStatus()
    this.refunds_loading = false
  }
  async getAdjustmentSingle() {
    this.adjustments_loading = true
    this.adjustments = await new DashboardServices().getAdjustmentStatus()
    this.adjustments_loading = false
  }
}
