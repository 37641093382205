export default class CustomerModel {
  _id: string
  number: string
  name: string
  code: string
  status: string
  email: string
  taxId: string
  telephone: string
  contact: string
  address: string
  contactName: string
  startDate: Date
  endDate: Date
  sequence: string
  createdAt: Date
  createdIP: string
  modifiedAt: Date
  modifiedIP: string
  constructor(jsonData: any) {
    this._id = jsonData._id
    this.number = jsonData.number
    this.name = jsonData.name
    this.code = jsonData.code
    this.status = jsonData.status
    this.email = jsonData.email
    this.taxId = jsonData.taxId
    this.telephone = jsonData.telephone
    this.contact = jsonData.contact
    this.address = jsonData.address
    this.contactName = jsonData.contactName
    this.startDate = new Date(jsonData.startDate)
    this.endDate = new Date(jsonData.endDate)
    this.sequence = jsonData.sequence
    this.createdAt = new Date(jsonData.createdAt)
    this.createdIP = jsonData.createdIP
    this.modifiedAt = new Date(jsonData.modifiedAt)
    this.modifiedIP = jsonData.modifiedIP
  }
  get startDateFormat() {
    try {
      return this.startDate.toThaiShort()
    } catch (error) {
      return "-"
    }
  }
  toJSON() {
    let obj: any = Object.assign({},this)
    return this
  }
}