import { PaginateResponse } from "@/common";
import ProductModel from "@/model/product";
import ProductServices from "@/service/product";
import { DateTime } from "luxon";
import { Service } from 'typedi';

@Service()
export class ActiveProductSingleClass {
  data?: ProductModel;
  loading: boolean = true;
  async getProductSingle(id: string) {
    this.loading = true
    this.data = await new ProductServices().getProductSingle(id)
    this.loading = false
  }
  async updateProductSingle() {
    this.loading = true
    this.data = await new ProductServices().updateProduct(this.data!._id!, this.data!)
    this.data = await new ProductServices().getProductSingle(this.data!._id!)
    this.loading = false
  }
  async createProductSingle() {
    this.loading = true
    this.data = await new ProductServices().createProduct(this.data!)
    this.loading = false
  }
  async deleteProductSingle(id: string) {
    this.loading = true
    this.data = await new ProductServices().deleteProductSingle(id)
    this.loading = false
  }
}

@Service()
export class ActiveProductListClass {
  data?: PaginateResponse<ProductModel>;
  loading: boolean = true;

  async getProductList(query: any) {
    this.loading = true
    this.data = await new ProductServices().getProductList(query)
    try {
      this.data!.docs.map(el=>{
        let warehouses = el.warehouses.sort((a,b)=>a.warehouseName.localeCompare(b.warehouseName))
        return {
          ...el,
          warehouses
        }
      })
    } catch (error) {
      
    }
    this.loading = false
  }

  async deleteProductFromList(id: string) {
    this.loading = true
    let tmp = await new ProductServices().deleteProductSingle(id)
    let index = this.data!.docs.findIndex(doc => doc._id === id)
    this.data!.docs.splice(index, 1)
    this.loading = false
  }

}