import ProductModel from "../product"
import WarehouseModel from "../warehouse"

export default class TransferModel {
  _id?: string
  recordDate: Date
  transferredDate: Date
  number: string
  sequence: string
  notificationDate: Date
  formWarehouse: string
  toWarehouse: string
  storeKeeper: string
  items: Array<TransferItemModel>
  status: string
  informant: string
  inspector: string
  address1: string
  address2: string
  createdAt: Date
  createdIP: string
  modifiedAt: Date
  modifiedIP: string

  constructor(jsonData: any) {
    this._id = jsonData._id
    this.recordDate = new Date(jsonData.recordDate)
    this.transferredDate = new Date(jsonData.transferredDate)
    this.number = jsonData.number??""
    this.items = (jsonData.items??[]).map((m:any)=>new TransferItemModel(m));
    this.sequence = jsonData.sequence??""
    this.notificationDate = new Date(jsonData.notificationDate)
    this.formWarehouse = jsonData.formWarehouse
    this.toWarehouse = jsonData.toWarehouse
    this.storeKeeper = jsonData.storeKeeper??""
    this.status = jsonData.status??"-"
    this.informant = jsonData.informant??""
    this.inspector = jsonData.inspector??""
    this.address1 = jsonData.address1??""
    this.address2 = jsonData.address2??""
    this.createdAt = new Date(jsonData.createdAt)
    this.createdIP = jsonData.createdIP??""
    this.modifiedAt = new Date(jsonData.modifiedAt)
    this.modifiedIP = jsonData.modifiedIP??""
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    obj.items.forEach((o:any)=>{
      try {
        
      } catch (error) {
        
      }
    })
    return obj
  }
}
export class TransferItemModel {
  _id? : string
  product: string|ProductModel
  warehouse: string|WarehouseModel
  warehouseName : string
  toWarehouse: string|WarehouseModel
  toWarehouseName: string
  series:string
  code: string
  color: string
  length: string
  qty: number
  unit: string
  detail: string
  note: string
  lot: string

  constructor(jsonData:any) {
    this._id = jsonData._id
    this.product = jsonData.product??""
    this.warehouse = jsonData.warehouse
    this.warehouseName = jsonData.warehouseName??""
    this.toWarehouse = jsonData.toWarehouse
    this.toWarehouseName = jsonData.toWarehouseName??""
    this.code = jsonData.code??""
    this.color = jsonData.color??""
    this.length = jsonData.length??""
    this.qty = jsonData.qty??0
    this.unit = jsonData.unit??""
    this.detail = jsonData.detail??""
    this.note = jsonData.note??""
    this.lot = jsonData.lot??""
    this.series = jsonData.series??""

  }
}